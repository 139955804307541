import { useContext } from 'react';
import Card from '../../../shared/Card';
import Spinner from '../../../shared/Spinner';
import PrivatePostsConext from '../PrivatePostsContext';
import Timestamp from '../Timestamp';
import WordsPerDay from '../WordsPerDay';
import PostsInfoToday from './PostsInfoToday';

const PostsInfo = ({ loading }: { loading: boolean }) => {
  const { analysis } = useContext(PrivatePostsConext);

  if (loading)
    return (
      <Card title="Your Stats">
        <Spinner size={undefined} />
      </Card>
    );

  const {
    count,
    existingPostsWordCountToday,
    highestWordCount,
    streaks,
    totalWordCount,
    wordCountsByDay,
  } = analysis;

  // IMPORTANT TODO: display of streaks needs to include info about today
  // for example, "and today" tags if covering the wordcount
  // or just today: wordcount and some warnings if not covering the other wordcounts

  return (
    // TODO comma delineated display
    // TODO more stats
    <Card title="Your Stats">
      <ul>
        <li>Posts: {count}</li>
        <li>Highest Word Count: {highestWordCount}</li>
        <li>Total Word Count: {totalWordCount}</li>
      </ul>
      <div>
        <h5 className={'mb-0'}>Streaks</h5>
        <em>You've written:</em>
      </div>
      <ul>
        {streaks &&
          Object.entries(streaks)
            .sort(
              ([aWordCount, aDate], [bWordCount, bDate]) =>
                +bWordCount - +aWordCount
            )
            .map(([wordCount, date]) => (
              <li key={wordCount}>
                <strong>{wordCount}</strong> words or more every day since{' '}
                <strong>
                  {/* TODO definitely want elapsed days here */}
                  <Timestamp date={date} hideHours={true} />
                </strong>
                .
              </li>
            ))}
      </ul>
      <PostsInfoToday
        existingPostsWordCountToday={existingPostsWordCountToday}
      />
      {/* TODO fix critical infinite loop potential */}
      {/* <WordsPerDay wordCountsByDay={wordCountsByDay} /> */}
    </Card>
  );
};
export default PostsInfo;
