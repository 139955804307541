import { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import AppContext from '../AppContext';
import MyDropdownToggle from './MyDropdownToggle';

export const PostActionsDropdown = ({ deletePost, editPost }) => {
  const { user } = useContext(AppContext);
  return (
    <Dropdown>
      <MyDropdownToggle />
      <Dropdown.Menu>
        {user.isPremium && editPost && (
          <>
            <Dropdown.Item as="button" onClick={editPost}>
              Edit
            </Dropdown.Item>
          </>
        )}
        {deletePost && (
          <Dropdown.Item as="button" onClick={deletePost}>
            Delete
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
