import React, { useState } from 'react';
import Button from 'react-bootstrap/button';
import Card from '../../../shared/Card';

const dailyActivitiesMatrix = [
  { title: 'starbucks pom20+', importance: 2 },
  { title: 'workout, shower, eat', importance: 1 },
  { title: 'second workout, shower, eat', importance: 5 },
  { title: 'respond to messages', importance: 4 },
  { title: 'build valuable relationsips', importance: 3 },
  { title: 'work on env: van, room, etc', importance: 3 },
];

const DailySchedule = () => {
  const [showImportanceLevel, setShowImportanceLevel] = useState(2);
  const importanceValues = dailyActivitiesMatrix
    .map((activity) => activity.importance)
    .filter((importance, index, self) => self.indexOf(importance) === index)
    .sort((a, b) => a - b);
  return (
    <Card title="Daily Schedule">
      <Card>
        {importanceValues.map((importance) => (
          <Button
            onClick={() => setShowImportanceLevel(importance)}
            className={importance === showImportanceLevel && 'active'}
          >
            {importance}
          </Button>
        ))}
      </Card>
      {dailyActivitiesMatrix
        .filter((activity) => activity.importance <= showImportanceLevel)
        .sort((a, b) => a.importance - b.importance)
        .map((activity) => (
          <Card title={activity.title}>importance: {activity.importance}</Card>
        ))}
    </Card>
  );
};

export default DailySchedule;
