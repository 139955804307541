// TODO update/strikethrough/hide steps and Walkthrough when complete
export default function Help() {
  return (
    <ol>
      {/* TODO have user manually create the 'my values' list */}
      <li>
        Select or Create a new <strong>List</strong>
      </li>
      {/* TODO have user add items to the 'my values' list */}
      <li>
        Add <strong>Items</strong> to the List
      </li>
      {/* TODO have user compare items in the 'my values' list */}
      <li>
        <strong>Compare</strong> items
      </li>
    </ol>
  );
}
