import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFastForward,
  faFighterJet,
  faPause,
  faPlay,
  faSave,
  faSkullCrossbones,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import Card from '../../../shared/Card';
import getRandomInt from '../../../shared/getRandomInt';

/**
 * TODO
 * - save game data to database
 * - residency and rate of change of residency should be affected by sentiment
 * - could build businesses and residents could earn income, taxRate could be a function of resident earnings
 * - wonderful opportunity for charts and graphs, like values over time, land-usage, etc
 * - more residents means more crime, high crime puts downward pressure on occupancy and move-in rate, police etc can downward pressure on crime rate
 * - actual challenges and ways to lose
 * - user can have multiple cities in a region, cities can specialize and import/export w region
 * - can build up education and then technology and then eventually colonize other planets etc
 * - faster speeds could be unlocked only by buying huge upgrades, or even prestiging
 */

// TODO make this shared and implement on all relevant apps and games
const BetaWarning = () => {
  const [show, setShow] = useState(true);
  return (
    show && (
      <Card
        bg="warning"
        title={
          <>
            beta version
            <CloseButton className="float-end" onClick={() => setShow(false)} />
          </>
        }
      >
        <ul>
          <li>Game incomplete but playable.</li>
          <li>Progress is saveable/loadable to browser localStorage.</li>
        </ul>
      </Card>
    )
  );
};

const LOCAL_STORAGE_KEY_NAME = 'simcity';

const MAX_DELAY_FOR_SETINTERVAL = 2 ** 31 - 1;
const GAME_SPEED_INTERVAL_WAY_TOO_EXTRA_EXTREMELY_FAST = 1;
const GAME_SPEED_INTERVAL_EXTREMELY_FAST = 20;
const GAME_SPEED_INTERVAL_FAST = 200;
const GAME_SPEED_INTERVAL_NORMAL = 2000;
const GAME_SPEED_INTERVAL_PAUSED = MAX_DELAY_FOR_SETINTERVAL;

const INITIAL_GAME_SPEED = GAME_SPEED_INTERVAL_NORMAL;
const INITIAL_HOMES = 0;
const INITIAL_LAND = 0;
const INITIAL_MONEY = 100000000;
const INITIAL_RESIDENTS = 0;
const INITIAL_SENTIMENT = 10; // 0 - 10
const INITIAL_TAX_RATE = 1;
const INITIAL_TIME = 0;

const COST_OF_LAND = 10000;
const COST_OF_HOME = 300000;

const Map = ({ land, homes }) => (
  <div style={{ whiteSpace: 'nowrap' }}>
    <div
      style={{
        background: 'beige',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        display: 'inline-block',
        height: Math.sqrt(land),
        width: Math.ceil(Math.sqrt(land) * (1 - homes / land)),
      }}
    ></div>
    <div
      style={{
        background: 'darkseagreen',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        display: 'inline-block',
        height: Math.sqrt(land),
        width: Math.floor(Math.sqrt(land) * (homes / land)),
      }}
    ></div>
  </div>
);

const incomeRate = ({ residents, taxRate }) => residents * taxRate;

const GameStateDisplay = ({ gameData }) => {
  if (!gameData) {
    return <pre>No gameData to display.</pre>;
  }
  const { homes, land, money, residents, sentiment, taxRate, time } = gameData;
  return (
    <div>
      <div>
        time: <strong>{Math.floor(time / 12)}</strong> years{' '}
        <strong>{time % 12}</strong> months
      </div>
      <div>
        money: <strong>${Number(money).toLocaleString()}</strong> USD{' '}
        <strong>
          {incomeRate({ residents, taxRate }) > 0 && '+'}$
          {Number(incomeRate({ residents, taxRate })).toLocaleString()}
        </strong>
        /month
      </div>
      <div>
        land: <strong>{Number(land).toLocaleString()}</strong> acres
      </div>
      <div>
        homes: <strong>{Number(homes).toLocaleString()}</strong> units
      </div>
      <div>
        residents: <strong>{Number(residents).toLocaleString()}</strong> (
        {homes === 0 ? 0 : Math.floor((residents * 100) / homes)}% occupancy)
      </div>
      <div>
        tax rate: <strong>${taxRate}</strong> USD / month / resident
      </div>
      <div>
        sentiment: <strong>{sentiment}</strong>/10 (0 bad - 10 good)
      </div>
    </div>
  );
};

const GameDataSection = ({
  jsonForLocalStorageFromGameState,
  setGameStateFromLocalStorageJson,
}) => {
  const [savedGameData, setSavedGameData] = useState({});
  const updateStateFromLocalStorage = () =>
    setSavedGameData(
      JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY_NAME))
    );
  useEffect(() => {
    updateStateFromLocalStorage();
  }, []);
  return (
    <Card bg="light" title="Game Data" className="mt-3">
      <div className="mb-1">
        <div>Found in browser localStorage:</div>
        <div className="mt-3">
          <Card style={{ display: 'inline-block' }}>
            <GameStateDisplay gameData={savedGameData} />
            <div>
              <Button
                className="ms-2 mt-2"
                onClick={() => {
                  window.localStorage.setItem(
                    LOCAL_STORAGE_KEY_NAME,
                    jsonForLocalStorageFromGameState()
                  );
                  updateStateFromLocalStorage();
                }}
              >
                <FontAwesomeIcon icon={faSave} /> Overwrite
              </Button>
              <Button
                className="ms-2 mt-2"
                onClick={() =>
                  setGameStateFromLocalStorageJson(
                    window.localStorage.getItem(LOCAL_STORAGE_KEY_NAME)
                  )
                }
              >
                <FontAwesomeIcon icon={faSave} /> Load
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
};

const SimCity = () => {
  // TODO would it be better for all this gameData to be in one state object? def easier to pass around
  const [gameSpeedInterval, setGameSpeedInterval] =
    useState(INITIAL_GAME_SPEED);
  const [homes, setHomes] = useState(INITIAL_HOMES);
  const [land, setLand] = useState(INITIAL_LAND);
  const [money, setMoney] = useState(INITIAL_MONEY);
  const [residents, setResidents] = useState(INITIAL_RESIDENTS);
  const [sentiment, setSentiment] = useState(INITIAL_SENTIMENT);
  const [taxRate, setTaxRate] = useState(INITIAL_TAX_RATE);
  const [time, setTime] = useState(INITIAL_TIME);

  const jsonForLocalStorageFromGameState = () =>
    JSON.stringify({
      homes,
      land,
      money,
      residents,
      sentiment,
      taxRate,
      time,
    });
  const setGameStateFromLocalStorageJson = (json) => {
    const { homes, land, money, residents, sentiment, taxRate, time } =
      JSON.parse(json);
    setHomes(homes);
    setLand(land);
    setMoney(money);
    setResidents(residents);
    setSentiment(sentiment);
    setTaxRate(taxRate);
    setTime(time);
  };

  // TODO add a reset button for current game data
  // TODO add a delete saved game button for localStorage
  useEffect(() => {
    if (window.localStorage.getItem(LOCAL_STORAGE_KEY_NAME)) {
      setGameStateFromLocalStorageJson(
        window.localStorage.getItem(LOCAL_STORAGE_KEY_NAME)
      );
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((time) => time + 1);
      const vacancies = homes - residents;
      setResidents((residents) =>
        Math.max(
          0,
          Math.ceil(residents + vacancies / 2) +
            getRandomInt(-residents / 40, residents / 40)
        )
      );
      // TODO money should also decline
      // at least at a rate proportional to your undeveloped land and unoccupied homes
      // as if you are ultimately responsible for maintaining the land or paying property tax to a higher government entity
      setMoney((money) => money + incomeRate({ residents, taxRate }));
      /**
       * Sentiment is a simple function of taxRate
       * TODO sentiment recovery (movement upward) should be gradual, not instant
       *
       * sentiment <= taxRate
       * 10           -Infinity - 0
       * 9            1
       * 8            2 - 3
       * 7            4 - 7
       * 6            8 - 15
       * 5            16 - 31
       * 4            32 - 63
       * 3            64 - 127
       * 2            128 - 255
       * 1            256 - 511
       * 0            512 - Infinity
       */
      setSentiment(
        Math.max(
          0,
          taxRate < 0 ? 10 : Math.ceil(10 - Math.max(0, Math.log2(taxRate) + 1))
        )
      );
    }, gameSpeedInterval);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [gameSpeedInterval, time]);

  const canBuyLand = (n) => money >= COST_OF_LAND * n;
  const buyLand = (n) => {
    if (canBuyLand(n)) {
      setMoney(money - COST_OF_LAND * n);
      setLand(land + n);
    }
  };
  const canBuildHome = (n) => land >= homes + n && money >= COST_OF_HOME * n;
  const buildHome = (n) => {
    if (canBuildHome(n)) {
      setMoney(money - COST_OF_HOME * n);
      setHomes(homes + n);
    }
  };
  const maxHomesCanBuild = () =>
    Math.min(land - homes, Math.floor(money / COST_OF_HOME));
  const adjustTaxRate = (n) => {
    setTaxRate((taxRate) => taxRate + n);
  };

  return (
    <>
      <BetaWarning />
      <Card title="Sim City">
        <Map land={land} homes={homes} />
        <GameStateDisplay
          gameData={{ homes, land, money, residents, sentiment, taxRate, time }}
        />
        <Card title="Actions" className="mt-3">
          <div className="mb-1">
            Game Speed
            <Button
              active={gameSpeedInterval === GAME_SPEED_INTERVAL_PAUSED}
              className="ms-2"
              onClick={() => setGameSpeedInterval(GAME_SPEED_INTERVAL_PAUSED)}
            >
              <FontAwesomeIcon icon={faPause} />
            </Button>
            <Button
              active={gameSpeedInterval === GAME_SPEED_INTERVAL_NORMAL}
              className="ms-2"
              onClick={() => setGameSpeedInterval(GAME_SPEED_INTERVAL_NORMAL)}
            >
              <FontAwesomeIcon icon={faPlay} />
            </Button>
            <Button
              active={gameSpeedInterval === GAME_SPEED_INTERVAL_FAST}
              className="ms-2"
              onClick={() => setGameSpeedInterval(GAME_SPEED_INTERVAL_FAST)}
            >
              <FontAwesomeIcon icon={faFastForward} />
            </Button>
            <Button
              active={gameSpeedInterval === GAME_SPEED_INTERVAL_EXTREMELY_FAST}
              className="ms-2"
              onClick={() =>
                setGameSpeedInterval(GAME_SPEED_INTERVAL_EXTREMELY_FAST)
              }
            >
              <FontAwesomeIcon icon={faFighterJet} />
            </Button>
            <Button
              active={
                gameSpeedInterval ===
                GAME_SPEED_INTERVAL_WAY_TOO_EXTRA_EXTREMELY_FAST
              }
              className="ms-2"
              onClick={() =>
                setGameSpeedInterval(
                  GAME_SPEED_INTERVAL_WAY_TOO_EXTRA_EXTREMELY_FAST
                )
              }
            >
              <FontAwesomeIcon icon={faSkullCrossbones} />
            </Button>
          </div>
          <div className="mb-1">
            Buy Land (${Number(COST_OF_LAND).toLocaleString()} / acre)
            {[1, 10, 100, 1000].map((n) => (
              <Button
                className="ms-2"
                disabled={!canBuyLand(n)}
                onClick={() => buyLand(n)}
              >
                +{n}
              </Button>
            ))}
          </div>
          <div className="mb-1">
            Build Home(s) (${Number(COST_OF_HOME).toLocaleString()})
            {[1, 10, 100].map((n) => (
              <Button
                className="ms-2"
                disabled={!canBuildHome(n)}
                onClick={() => buildHome(n)}
              >
                +{n}
              </Button>
            ))}
            <Button
              className="ms-2"
              disabled={!canBuildHome(1)}
              onClick={() => buildHome(maxHomesCanBuild())}
            >
              +Max
            </Button>
          </div>
          <div className="mb-1">
            Tax Rate
            {[-1, 1].map((n) => (
              <Button className="ms-2" onClick={() => adjustTaxRate(n)}>
                {n > 0 ? '+' + n : n}
              </Button>
            ))}
          </div>
        </Card>
        <GameDataSection
          jsonForLocalStorageFromGameState={jsonForLocalStorageFromGameState}
          setGameStateFromLocalStorageJson={setGameStateFromLocalStorageJson}
        />
      </Card>
    </>
  );
};
export default SimCity;
