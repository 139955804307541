import { db } from '../../../../components/firebase.js';

const listsRef = ({ uid }) =>
  db
    .collection('lists')
    .doc('data')
    .collection('users')
    .doc(uid)
    .collection('lists');

const listRef = ({ uid, listId }) => listsRef({ uid }).doc(listId);

const itemsRef = ({ uid, listId }) =>
  listRef({ uid, listId }).collection('items');
const itemRef = ({ uid, listId, id }) => itemsRef({ uid, listId }).doc(id);

const relationshipsRef = ({ uid, listId }) =>
  listRef({ uid, listId }).collection('relationships');
const relationshipRef = ({ uid, listId, id }) =>
  relationshipsRef({ uid, listId }).doc(id);

// this one function could rule them all but is a heavy hitter monolith
export const getLists = (uid, callback = () => null) => {
  const lists = {};
  listsRef({ uid })
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((list) => {
        lists[list.id] = list.data();
        lists[list.id].id = list.id;

        // handles and fixes old lists with no name
        // TODO fix all existing lists and delete this code
        if (typeof lists[list.id].name === 'undefined') {
          lists[list.id].name = list.id;
          // and permanently set the name
          listRef({ uid, listId: list.id }).set(
            { name: list.id },
            { merge: true }
          );
        }
      });
      callback && typeof callback === 'function' && callback(lists);
    });
};
export const createList = (userId, name, callback) => {
  if (!userId) return;
  listsRef({ uid: userId })
    .add({
      name,
    })
    .then((docRef) => {
      callback && typeof callback === 'function' && callback(docRef.id);
    });
};
// TODO in revieing db, this appears it only deletes the middle node; need to delete downstream childrens too
// https://firebase.google.com/docs/firestore/manage-data/delete-data#collections
export const deleteList = (userId, listId, callback) => {
  if (!userId || !listId) return;
  listRef({ uid: userId, listId }).delete();
  callback && typeof callback === 'function' && callback();
};

export const getItems = (userId, listId, callback) => {
  const items = {};
  itemsRef({ uid: userId, listId })
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((item) => {
        items[item.id] = item.data();
        items[item.id].id = item.id;
      });
      callback && typeof callback === 'function' && callback(items);
    });
};
//TODO works for create. does this work for update?
export const upsertItem = (userId, listId, item, callback) => {
  if (!item.id) return;
  itemRef({ uid: userId, listId, id: item.id })
    .set(item, { merge: true })
    .then(() => {
      callback && typeof callback === 'function' && callback(item.id);
    });
};
// export const deleteItem = (userId, listId, itemId, callback) => {
//   if (!itemId) return;
//   itemRef({ uid: userId, listId, id: itemId }).delete();
//   callback && typeof callback === 'function' && callback();
// };
export const deleteItemAndRelationships = (
  userId,
  listId,
  itemId,
  callback
) => {
  if (!itemId) return;
  itemRef({ uid: userId, listId, id: itemId }).delete();
  deleteItemRelationships(userId, listId, itemId);
  callback && typeof callback === 'function' && callback();
};

export const getRelationships = (userId, listId, callback) => {
  const relationships = {};
  relationshipsRef({ uid: userId, listId })
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((relationship) => {
        relationships[relationship.id] = relationship.data();
        relationships[relationship.id].id = relationship.id;
      });
      callback && typeof callback === 'function' && callback(relationships);
    });
};
//TODO works for create. does this work for update?
export const upsertRelationship = (userId, listId, relationship, callback) => {
  if (!relationship.options || !relationship.selection) return;
  relationshipRef({
    uid: userId,
    listId,
    id: relationship.id,
  })
    .set(relationship, { merge: true })
    .then(() => {
      callback && typeof callback === 'function' && callback(relationship.id);
    });
};
export const deleteRelationship = (
  userId,
  listId,
  relationshipId,
  callback
) => {
  if (!relationshipId) return;
  relationshipRef({
    uid: userId,
    listId,
    id: relationshipId,
  }).delete();
  callback && typeof callback === 'function' && callback();
};

const getItemRelationships = (userId, listId, itemId, callback) => {
  const relationships = {};
  relationshipsRef({ uid: userId, listId })
    .where('options', 'array-contains', itemId)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((relationship) => {
        relationships[relationship.id] = relationship.data();
        relationships[relationship.id].id = relationship.id;
      });
      callback && typeof callback === 'function' && callback(relationships);
    });
};
export const deleteItemRelationships = (userId, listId, itemId, callback) => {
  getItemRelationships(userId, listId, itemId, (relationships) => {
    Object.keys(relationships).forEach((relationshipId) => {
      deleteRelationship(userId, listId, relationshipId);
    });
    callback && typeof callback === 'function' && callback(relationships);
  });
};
