import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdjust,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
} from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';

import './FullScreen.css';

const DEFAULT_DARKMODE_VALUE = false;
const DEFAULT_FULLSCREEN_VALUE = false;
const FullScreenContext = React.createContext({
  darkMode: DEFAULT_DARKMODE_VALUE,
  fullScreen: DEFAULT_FULLSCREEN_VALUE,
});

export const FullScreenProvider = ({ children }) => {
  const [fullScreen, setFullScreen] = useState(false); // TODO: DEFAULT_FULLSCREEN_VALUE
  const [darkMode, setDarkMode] = useState(false); // TODO DEFAULT_DARKMODE_VALUE
  return (
    <FullScreenContext.Provider
      value={{
        darkMode,
        fullScreen,
        setDarkMode,
        setFullScreen,
      }}
    >
      {children}
    </FullScreenContext.Provider>
  );
};

export const FullScreenHide = ({ children }) => {
  const { fullScreen } = useContext(FullScreenContext);
  return fullScreen ? null : children;
};

// TODO this component should be unnecessary
export const FullScreenContentWrapper = ({ render }) => {
  const { darkMode, fullScreen } = useContext(FullScreenContext);
  return render({ darkMode, fullScreen });
};

export const FullScreenOnButton = ({ onSuccess }) => {
  const { fullScreen, setFullScreen } = useContext(FullScreenContext);
  return (
    !fullScreen && (
      <Button
        className="float-end"
        variant="light"
        onClick={() => {
          setFullScreen(true);
          onSuccess();
        }}
      >
        <FontAwesomeIcon icon={faExpandArrowsAlt} />
      </Button>
    )
  );
};

const DarkModeToggleButton = ({ onSuccess }) => {
  const { darkMode, fullScreen, setDarkMode } = useContext(FullScreenContext);
  return (
    fullScreen && (
      <Button
        variant={darkMode ? 'dark' : 'light'}
        className="text-muted"
        onClick={() => {
          setDarkMode(!darkMode);
          onSuccess();
        }}
      >
        <FontAwesomeIcon icon={faAdjust} />
      </Button>
    )
  );
};

// TODO this has become slow!  Fix or at least disabled and add a loading Spinner
// TODO this should scroll to your textarea not just set focus (may handle via onSuccess)
const FullScreenOffButton = ({ onSuccess }) => {
  const { darkMode, fullScreen, setDarkMode, setFullScreen } =
    useContext(FullScreenContext);
  return (
    fullScreen && (
      <Button
        variant={darkMode ? 'dark' : 'light'}
        className="text-muted"
        onClick={() => {
          setDarkMode(false);
          setFullScreen(false);
          onSuccess();
        }}
      >
        <FontAwesomeIcon icon={faCompressArrowsAlt} />
      </Button>
    )
  );
};

export const FullScreenButtons = ({ onClickSuccess }) => (
  <div className="fullscreen-buttons-container">
    <FullScreenOffButton onSuccess={onClickSuccess} />
    <DarkModeToggleButton onSuccess={onClickSuccess} />
  </div>
);
