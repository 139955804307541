import Card from '../../../../shared/Card';
import countWords from '../../../../shared/countWords';
import Linkify from '../../../../shared/Linkify';
import { PostActionsDropdown } from '../../../../shared/Post';
import ModalityButton from '../../ModalityButton';
import Timestamp from '../../Timestamp';

const PostContent = ({ children }) => (
  <div
    style={{
      whiteSpace: 'break-spaces',
      wordBreak: 'break-word',
    }}
  >
    <Linkify>{children}</Linkify>
  </div>
);

function Post({ deletePost = () => null, post = {} }) {
  const { content, id, modalities, timestamp } = post;
  return (
    <Card>
      <div>
        <div className="float-end">
          <PostActionsDropdown deletePost={deletePost} />
        </div>
        <Timestamp date={timestamp} />
      </div>
      {modalities && (
        <div className="mt-2">
          {Object.keys(modalities).map((modalityId) => (
            <ModalityButton
              key={modalityId}
              modalityId={modalityId}
              size="sm"
              uniqueIdSuffixForToggle={id}
            />
          ))}
        </div>
      )}
      <hr />
      <PostContent>{content}</PostContent>
      <hr />
      <div className="text-muted float-end">{countWords(content)}</div>
    </Card>
  );
}

export default Post;
