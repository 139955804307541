import { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import AppContext from '../AppContext';

const Login403DisallowedUseragent = () => (
  <div className="mt-3" style={{ font: 'consolas' }}>
    <h2>Login not working?</h2>
    <p>
      <strong>Problem:</strong> Did you see{' '}
      <strong>403 disallowed_useragent</strong> when trying to login?
    </p>
    <p style={{ fontSize: '2rem' }}>
      <strong>Solution:</strong> Open xbk.io in a browser.
    </p>
    <p>
      <strong>Explanation:</strong> As of April 20, 2017, Google has blocked
      OAuth authorization requests via web views aka embedded browsers. If you
      opened xbk.io from Facebook or any other app utilizing embedded browsers
      then you will see '403 disallowed_useragent' and be unable to login.
    </p>
  </div>
);

export const LogInButton = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style: React.CSSProperties;
}) => {
  const { login } = useContext(AppContext);
  return (
    <Button variant="primary" onClick={login} style={style}>
      {children || 'Log In'}
    </Button>
  );
};
