import React from 'react';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import { Beta, Favorite } from '../shared/icons';

const AppNav = () => {
  return (
    <Nav className="justify-content-center">
      <NavDropdown title="Public">
        <NavDropdown.Item>
          <Nav.Link as={NavLink} to="/public">
            Home
            <Favorite />
          </Nav.Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Nav.Link as={NavLink} to="/rooms">
            Rooms
          </Nav.Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Nav.Link as={NavLink} to="/training">
            Training
            <Favorite />
          </Nav.Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Nav.Link as={NavLink} to="/queues">
            Queues
          </Nav.Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Nav.Link as={NavLink} to="/community">
            Community
          </Nav.Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Nav.Link as={NavLink} to="/events">
            Events <Beta />
          </Nav.Link>
        </NavDropdown.Item>
      </NavDropdown>
      <Nav.Link as={NavLink} to="/private">
        Private
      </Nav.Link>
      <Nav.Link as={NavLink} to="/games">
        Games
      </Nav.Link>
    </Nav>
  );
};

export default AppNav;
