import React, { Component, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withRouter } from 'react-router';
import firebase from '../../firebase.js';
import AppContext from '../../AppContext';
import postsTreeFromRawPosts from '../../shared/postsTreeFromRawPosts';
import Spinner from '../../shared/Spinner';
import NewTopLevelPostCard from './NewTopLevelPostCard';
import Post from './Post';
import {
  FEED,
  FeedNav,
  getFeedFilterByTags,
  getFeedPreference,
  getFollowingFeed,
  getHotFeed,
  getPopularFeed,
  getUnseenFeed,
} from './Feed';
import { PremiumFeature, PremiumSaleCard } from '../../shared/Premium';
import Mosaic from '../Community/Mosaic';
import Modality from '../../shared/Modalities';
import PostsFeed from '../../shared/PostsFeed';
import SearchFilter from './SearchFilter';

const RoomCard = ({ room }) => (
  <>
    <Card
      className="mb-3"
      style={{
        backgroundColor: room.color || 'inherit',
      }}
    >
      <Card.Body>
        <Card.Title>{room.title}</Card.Title>
        {room.description}
      </Card.Body>
    </Card>
    {room.id === 'healthyrelating' && (
      <div className="mb-3">
        <Mosaic room={room.id} size={48} title={'Members'} />
      </div>
    )}
  </>
);

const LoadPostsButton = ({ children, loadPosts, loadedPosts, loadingPosts }) =>
  loadedPosts ? (
    children
  ) : loadingPosts ? (
    <>
      <Spinner size="lg" />
      <span>Loading posts...</span>
    </>
  ) : (
    <Button onClick={loadPosts}>Load Posts</Button>
  );

const PostsNavSearchFeed = ({
  currentFeed,
  displayIsolatedNonTree,
  doSearch,
  feedSubtext,
  feedsToHide,
  isUnseenFeed, // TODO DRY this
  loadPosts,
  loadedPosts,
  loadingPosts,
  posts,
  setFeed,
  setPostsFilter,
}) => {
  const { user } = useContext(AppContext);
  return (
    <LoadPostsButton
      loadPosts={loadPosts}
      loadedPosts={loadedPosts}
      loadingPosts={loadingPosts}
    >
      <div className="mb-2">
        <FeedNav
          userIsPremium={user.isPremium}
          hideFeedsByTitle={feedsToHide}
          currentFeed={currentFeed}
          setFeed={setFeed}
          setPostsFilter={setPostsFilter}
          feedSubtext={feedSubtext}
        />
      </div>
      <div className="mb-4">
        <SearchFilter doSearch={doSearch} />
      </div>
      <PostsFeed
        posts={posts}
        isUnseenFeed={isUnseenFeed}
        showHeaderLinkToParent={displayIsolatedNonTree}
        hackHideRepliesCount={displayIsolatedNonTree}
      />
    </LoadPostsButton>
  );
};

const feedsNotSupportedByUserProfilePage = ['following'];

const getPostsByTimestampObjectWithUserDataFromPostsArray = (
  flatPostsArray = [],
  users = {}
) => {
  const postsByTimestamp = {};
  flatPostsArray.forEach((post) => {
    if (users) {
      post.userDisplayName =
        (users[post.userId] && users[post.userId].displayName) || 'Loading...';
      post.userPhotoURL =
        (users[post.userId] && users[post.userId].photoURL) || null;
    }
    postsByTimestamp[post.timestamp] = post;
  });
  return postsByTimestamp;
};

const filterPosts = (posts = [], filter = '') => {
  let filteredPosts = posts;
  if (filter && filter !== '') {
    filteredPosts = posts.filter((post) => {
      return (
        (post.content && post.content.indexOf(filter) !== -1) ||
        (post.userDisplayName && post.userDisplayName.indexOf(filter) !== -1) ||
        (post.tags &&
          Object.values(post.tags).some(
            (tag) => tag.type.indexOf(filter) !== -1
          ))
      );
    });
  }
  return filteredPosts;
};

const searchTree = ({ postId, post, key = 'childNodes' }) => {
  if (post.id === postId) {
    return post;
  } else if (post[key]) {
    var i;
    var result = null;
    for (i = 0; result == null && i < post[key].length; i++) {
      result = searchTree({ postId, post: post[key][i] });
    }
    return result;
  }
  return null;
};

const getPosts = ({ roomId, userFeedUid, userIsPremium }, callback) => {
  const firebaseDatabaseRefPosts = firebase.database().ref('posts');

  let postsRef = null;
  if (!userIsPremium) {
    postsRef = firebaseDatabaseRefPosts.orderByChild('room').equalTo('general');
  } else if (userFeedUid || roomId === 'home') {
    postsRef = firebaseDatabaseRefPosts;
  } else if (roomId) {
    postsRef = firebaseDatabaseRefPosts.orderByChild('room').equalTo(roomId);
  }

  postsRef.on('value', (postsSnapshot) => {
    let posts = postsSnapshot.val();
    posts &&
      Object.entries(posts).forEach(([id, post]) => {
        posts[id] = { ...post, id };
      });
    callback(posts);
  });
};

class Posts extends Component {
  constructor() {
    super();
    this.state = {
      loadingPosts: false,
      loadedPosts: false,
      posts: {},
      feed: '',
      postsFilter: {
        requiredTags: [],
        forbiddenTagsByMe: [],
      },
      searchFilterString: '',
    };
    this.loadPosts = this.loadPosts.bind(this);
    this.setPostsFilter = this.setPostsFilter.bind(this);
    this.setSearchFilterString = this.setSearchFilterString.bind(this);
  }

  static contextType = AppContext;
  modality = () => this.context.modality;
  user = () => this.context.user;
  users = () => this.context.users;

  setPostsFilter(requiredTags, forbiddenTagsByMe) {
    this.setState({
      postsFilter: {
        requiredTags: requiredTags,
        forbiddenTagsByMe: forbiddenTagsByMe,
      },
    });
  }

  setSearchFilterString(value) {
    this.setState({
      searchFilterString: value,
    });
  }

  loadPosts() {
    this.setState({ loadingPosts: true });
    const { room, userFeedUid } = this.props;
    let feed = getFeedPreference() || FEED.HOT;
    getPosts(
      {
        roomId: room?.id,
        userFeedUid,
        userIsPremium: this.user().isPremium,
      },
      (posts) => {
        if (userFeedUid && feedsNotSupportedByUserProfilePage.includes(feed)) {
          // TODO this does not setFeedPreference for user, it's temporary, is okay/desirable?
          feed = FEED.RECENT;
        }
        if (this.props.match.params.postId && feed === FEED.ALL_ACTIVITY) {
          feed = FEED.RECENT;
        }
        this.setState({ feed, loadingPosts: false, loadedPosts: true, posts });
      }
    );
  }

  componentDidMount() {
    const isSinglePostPage = !!this.props.match.params.postId;
    isSinglePostPage && this.loadPosts();
  }

  render() {
    if (
      !this.user().isPremium &&
      this.props.room.requires &&
      this.props.room.requires.includes('premium')
    ) {
      return <PremiumFeature featureName={'Premium rooms'} />;
    }

    const postId = this.props.match.params.postId;
    const isSinglePostPage = !!postId;
    const isFeedPage = !isSinglePostPage;
    const isUserProfilePage = !!this.props.userFeedUid;
    const users = this.users();
    let feedSubtext = null;
    let post = {};
    let postsTree = [];
    let displayIsolatedNonTree = false;
    const feedsToHide =
      this.props.userFeedUid && feedsNotSupportedByUserProfilePage;

    // TODO loadingPosts need not block everything
    if (!users) {
      return <Spinner size="lg" />;
    }

    if (this.state.posts && this.state.posts.length !== 0) {
      const flatPostsArray = Object.values(this.state.posts);

      let filteredPostsArray = flatPostsArray;

      /**
       * Filter flat posts for feed
       */
      if (isFeedPage) {
        if (this.state.feed === FEED.FILTER_BY_TAGS) {
          const { postsFilter } = this.state;
          [filteredPostsArray, feedSubtext] = getFeedFilterByTags({
            flatPostsArray,
            postsFilter,
            myUserId: this.user().uid,
          });
        }
      }

      /**
       * Filter flat posts for search filter
       */
      const filteredPosts = filterPosts(
        filteredPostsArray,
        this.state.searchFilterString
      );

      /**
       * All Activity feed does not use postTree
       */
      if (this.state.feed === FEED.ALL_ACTIVITY) {
        //TODO there is a lot of unnecessary switching from posts object to array to object to array
        const postsByTimestampObjectWithUserData =
          getPostsByTimestampObjectWithUserDataFromPostsArray(
            filteredPosts,
            users
          );
        postsTree = Object.values(postsByTimestampObjectWithUserData).sort(
          (a, b) => b.timestamp - a.timestamp
        );
        displayIsolatedNonTree = true;
        //TODO put this in Feed.js
        feedSubtext =
          'All posts and replies, chronologically, and isolated from thread trees';
      } else {
        /**
         * Build postsTree
         */
        postsTree = postsTreeFromRawPosts({
          flatPostsArray: filteredPosts,
          users,
        });

        if (isSinglePostPage) {
          /**
           * Find single post in postsTree
           */
          post = searchTree({ postId, post: { childNodes: postsTree } });
          if (!post) {
            post = null; // not found
          }
        } else {
          /**
           * Filter postsTree for feed
           */
          if (this.state.feed === FEED.FOLLOWING) {
            [postsTree, feedSubtext] = getFollowingFeed({
              posts: postsTree,
              userFollowingUids: this.user().following,
            });
          }
          if (this.state.feed === FEED.UNSEEN) {
            [postsTree, feedSubtext] = getUnseenFeed({
              flatPostsArray,
              posts: postsTree,
              userId: this.user().uid,
            });
          }
          if (this.state.feed === FEED.POPULAR) {
            [postsTree, feedSubtext] = getPopularFeed({ posts: postsTree });
          }
          if (this.state.feed === FEED.HOT) {
            [postsTree, feedSubtext] = getHotFeed({ posts: postsTree });
          }
        }
      }
    }

    /**
     * User Profile Page
     */
    if (isUserProfilePage) {
      const postsTreeForUserFeed = postsTree.filter(
        (post) => post.userId === this.props.userFeedUid
      );
      return (
        <PostsNavSearchFeed
          feedsToHide={feedsToHide}
          currentFeed={this.state.feed}
          setFeed={(feed) => this.setState({ feed: feed })}
          setPostsFilter={this.setPostsFilter}
          feedSubtext={feedSubtext}
          doSearch={this.setSearchFilterString}
          posts={postsTreeForUserFeed}
          // TODO can add: isUnseenFeed={this.state.feed === FEED.UNSEEN}
          showHeaderLinkToParent={displayIsolatedNonTree}
          hackHideRepliesCount={displayIsolatedNonTree}
          loadPosts={this.loadPosts}
          loadedPosts={this.state.loadedPosts}
          loadingPosts={this.state.loadingPosts}
        />
      );
    }

    const isRoomPage = !!this.props.room.id;
    const showModality = this.user().isPremium && this.modality() && isFeedPage;

    return (
      <Row>
        <Col className="col-left">
          {isRoomPage && (
            <>
              <RoomCard room={this.props.room} />
              {showModality && (
                <div className="mb-3">
                  <Modality />
                </div>
              )}
            </>
          )}
          {!this.user().isPremium && <PremiumSaleCard />}
        </Col>
        <Col sm={8} className="col-main">
          {isSinglePostPage && (
            <Post
              //TODO parentPostAuthorUid
              loading={!this.state.loadedPosts}
              post={post}
              hackIsSinglePostPage={isSinglePostPage}
              hackRoom={this.props.room.id}
              showHeaderLinkToParent={true}
            />
          )}
          {isFeedPage && (
            <>
              <div className="mb-3">
                <NewTopLevelPostCard hackRoom={this.props.room.id} />
              </div>
              <PostsNavSearchFeed
                feedsToHide={feedsToHide}
                currentFeed={this.state.feed}
                setFeed={(feed) => this.setState({ feed: feed })}
                setPostsFilter={this.setPostsFilter}
                feedSubtext={feedSubtext}
                doSearch={this.setSearchFilterString}
                posts={postsTree}
                isUnseenFeed={this.state.feed === FEED.UNSEEN}
                showHeaderLinkToParent={displayIsolatedNonTree}
                hackHideRepliesCount={displayIsolatedNonTree}
                loadPosts={this.loadPosts}
                loadedPosts={this.state.loadedPosts}
                loadingPosts={this.state.loadingPosts}
              />
            </>
          )}
        </Col>
      </Row>
    );
  }
}

export default withRouter(Posts);
