import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import AppContext from '../../../AppContext';
import Card from '../../../shared/Card';
import FriendlyTimestamp from '../../../shared/timestamp';
import { createRoom, getRooms } from './data';

const JackboxHome = () => {
  const { user } = useContext(AppContext);
  const [rooms, setRooms] = useState({});
  const [loading, setLoading] = useState(true);

  const reloadRooms = (callback = () => null) => {
    getRooms((rooms) => {
      setRooms(rooms);
      callback(rooms);
    });
  };

  useEffect(() => {
    reloadRooms(() => setLoading(false));
  }, []);

  return (
    <Card loading={loading} title="Jackbox">
      <Button
        onClick={() => {
          setLoading(true);
          createRoom({ type: 'fictionary', uid: user.uid }, () => {
            reloadRooms();
            setLoading(false);
          });
        }}
      >
        Create New Fictionary Game Room
      </Button>
      <Card>
        {rooms &&
          Object.entries(rooms).map(([id, room]) => (
            <div key={id}>
              <Link to={'/jackbox/' + room.type + '/' + id}>join</Link>{' '}
              {FriendlyTimestamp(room.timestamp, ' ago')} {room.type} {id}{' '}
            </div>
          ))}
      </Card>
    </Card>
  );
};
export default JackboxHome;
