/**
 * This regex matches any non-whitespace character one and unlimited times
 * with a negative lookahead and negative lookbehind
 * for any and all ascii punctuation or symbols by unicode range
 */
/**
 * 2021-01-02 found bug on mobile, site won't load
 * probably negative lookbehind unsupported
 */
// const re = /(?![!-/:-@[-`{-~])\S+(?<![!-/:-@[-`{-~])/g;
const re = /\w+([!-/:-@[-`{-~]*\w+)*/g; // nearly the same as above but \w accepts underscores as a word character

export const words = (str: string) => str.match(re) || [];

const countWords = (str: string) => words(str).length;
export default countWords;
