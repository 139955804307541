import React from 'react';
import Spinner from '../../../shared/Spinner';

const PrivatePostsCount = ({ showing, total }) => (
  <div className="my-3 small">
    <em>
      Showing <strong>{showing}</strong> of your <strong>{total}</strong> total
      private posts from newer to older in reverse chronological order:
    </em>
  </div>
);

const PostsFeed = ({ loading, posts, renderPost }) => {
  return loading ? (
    <Spinner />
  ) : (
    <>
      <PrivatePostsCount showing={posts.length} total={posts.length} />
      {posts.map((post) => renderPost(post))}
    </>
  );
};
export default PostsFeed;
