import firebase from '../../../../components/firebase.js';

const jackboxRoomsRef = () => firebase.database().ref('games/jackbox/rooms');
const jackboxRoomRef = (id) =>
  firebase.database().ref('games/jackbox/rooms/' + id);
const jackboxRoomUsersRef = (id) =>
  firebase.database().ref('games/jackbox/rooms/' + id + '/users');

const getRoom = (id, callback = () => null) => {
  if (!id) return;
  jackboxRoomRef(id)
    .once('value')
    .then((snapshot) => {
      const room = snapshot.val();
      room.id = snapshot.key;
      callback && typeof callback === 'function' && callback(room);
    });
};
export const streamRoom = (id, callback = () => null) => {
  if (!id) return;
  jackboxRoomRef(id).on('value', (snapshot) => {
    const room = snapshot.val();
    room.id = snapshot.key;
    callback && typeof callback === 'function' && callback(room);
  });
};

export const getRooms = (callback = () => null) => {
  jackboxRoomsRef().once('value', (snapshot) => {
    const rooms = snapshot.val();
    callback && typeof callback === 'function' && callback(rooms);
  });
};

export const createRoom = (room = {}, callback = () => null) => {
  const id = jackboxRoomsRef().push().key;
  room.timestamp = firebase.database.ServerValue.TIMESTAMP;
  jackboxRoomsRef()
    .child(id)
    .set(room)
    .then(callback && typeof callback === 'function' && callback());
};

export const updateRoom = (id, room, callback = () => null) => {
  if (!id) return;
  jackboxRoomsRef()
    .child(id)
    .update(room)
    .then(callback && typeof callback === 'function' && callback());
};

export const addUserToRoom = (
  { uid, username, roomId },
  callback = () => null
) => {
  getRoom(roomId, (room) => {
    jackboxRoomUsersRef(roomId)
      .update({ [uid]: { uid, username } })
      .then(callback && typeof callback === 'function' && callback());
  });
};
