import firebase, { db } from '../components/firebase.js';

export const getAccounting = (callback) => {
  const accounting = {};
  db.collection('accounting')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        accounting[doc.id] = doc.data();
        accounting[doc.id].id = doc.id;
      });
      callback(accounting);
    });
};

export const createAccounting = (
  {
    notes,
    type,
    uid,
    amount,
    currencyCode,
    via,
    createdAt,
    paypalTransactionId,
  },
  callback
) => {
  db.collection('accounting')
    .doc()
    .set({
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      notes,
      type,
      uid,
      amount,
      currencyCode,
      via,
      paypalTransactionId,
    })
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};
