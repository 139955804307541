export interface WordCountsByDay {
  [dateString: string]: number;
}

// TODO tests
export const getDateStringForLocal = (date: Date) => {
  const yearDashMonthDashDay = date.toLocaleDateString('en-CA');
  return yearDashMonthDashDay; //yyy-mm-dd
};

// TODO not used, but maybe useful for site-wide competitions;
// though site-wide competitions would consider every users streaks within their own timezones
export const getDateString = (date: Date) => {
  const yearDashMonthDashDay = date.toLocaleDateString('en-CA', {
    timeZone: 'UTC',
  });
  return yearDashMonthDashDay; //yyy-mm-dd
};
