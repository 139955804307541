import firebase from '../../../firebase.js';
import { RowColType } from './types.js';

const FB_DB_GAMES_KEY = 'chessworld';
// TODO change actual db so can use: const FB_DB_GAMES_KEY = 'cultivation'
// or an entirely different database (? :thinking:)

const FB_DB_REF = `games/${FB_DB_GAMES_KEY}`;

// THIS IS HIGH POWER STUFF, MAKE SAFE AND KEEP PROMINENT BEHIND ADMIN PROTECTIONS
// function moveGameKeyToNewGameKey({ fromKey, toKey }, options) {
//   const gamesRef = firebase.database().ref('games');
//   gamesRef.child(fromKey).once('value').then(function(snap) {
//   var data = snap.val();
//   var update = {};
//   update[toKey] = data;
//   // if (options.deleteFromKey) {
//   //   update[fromKey] = null;
//   // }
//   return gamesRef.update(update);
// }
// function copyGameKeyToNewGameKey({fromKey, toKey}) {
//   moveGameKeyToNewGameKey({fromKey, toKey}, { deleteFromKey: false})
// }

// TODO:
// copyGameKeyToNewGameKey({fromKey: 'chessworld', toKey: 'cultivation'})

// export const cellsRef = () => gameRef.ref(`cells`);
// or
// export const cellsRef = () => gameRef.child(`cells`);
// whatever works for their library syntax

export function increment(n: number) {
  return firebase.database.ServerValue.increment(n);
}
export const increment1 = firebase.database.ServerValue.increment(1);

export const cellsRef = () =>
  firebase.database().ref(`games/${FB_DB_GAMES_KEY}/cells`);
export const userRef = (uid: string) =>
  firebase.database().ref(`games/${FB_DB_GAMES_KEY}/users/${uid}`);

export async function makeGrass(rowCol: RowColType) {
  cellsRef()
    .child(`${rowCol[0]}/${rowCol[1]}/grass`)
    .transaction(function () {
      return true;
    });
}

export async function makeDirt(rowCol: RowColType) {
  cellsRef()
    .child(`${rowCol[0]}/${rowCol[1]}/grass`)
    .transaction(function () {
      return false;
    });
  // userRef(firebase.auth().currentUser.uid)
  //   .child('dirt')
  //   .transaction(function (dirt) {
  //     if (dirt) {
  //       return dirt + 1;
  //     }
  //     return 1;
  //   });
}
