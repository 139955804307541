import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/button';
import Card from '../../../shared/Card';

const DisplayControl = ({ display, control, units = false }) => (
  <Card>
    <div>
      <span style={{ fontSize: '4em' }}>{display}</span>
      {units && ` ${units}`}
    </div>
    <div>{control}</div>
  </Card>
);

const Counter = ({ buttonText, count, onClick, units = false }) => {
  return (
    <DisplayControl
      display={count}
      control={
        <Button onClick={() => onClick && onClick()}>
          {buttonText ? buttonText : 'Click'}
        </Button>
      }
      units={units}
    />
  );
};

const howManyTimeForATimeBall = 1;
const howManyTimeBallsForASpaceBall = 3;

const worldInitialState = {
  time: 0,
  timeBalls: 0,
  spaceBalls: 0,
  // forceBalls: 0,
  // energyBalls: 0,
  // matterBalls: 0,
  // consciousBalls: 0,
  // englightenedBalls: 0,
  // godBalls: 0,
  // voidBalls: 0,
  // ballsBalls: 0,
  // beyondBalls: 0,
  // ballCollectives: 0,
  // ballUtopias: 0,
  // ballHeavens: 0,
  // ballImmortalities: 0,
  // ballGods: 0,
  // oneGods: 0,
  // moreOnelierGods: 0,
  // oneliests: 0,
  // uniqueliestClickersOfSuchHeights: 0, // users who manage to click to produce one of these are added to hall of fame esp in the shared public implementation
};

const TimeBalls = () => {
  const [world, setWorld] = useState(worldInitialState);
  // @TODO the following is terribly built and close to the most important part of game design, fix this asap
  useEffect(() => {
    const intervalId = setInterval(() => {
      setWorld({ ...world, time: world.time + 1 });
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [world]);

  return (
    <Card title="TimeBalls">
      <DisplayControl display={world.time} units={'elapsed time'} />
      <Card title="Ball Pit">
        <Counter
          count={world.timeBalls}
          units="Time Balls"
          buttonText={
            <>
              {`+ 1 Time Balls`}
              <br />
              {`- ${howManyTimeForATimeBall} elapsed times`}
            </>
          }
          onClick={() => {
            if (world.time >= howManyTimeForATimeBall) {
              setWorld({
                ...world,
                time: world.time - howManyTimeForATimeBall,
                timeBalls: world.timeBalls + 1,
              });
            } else {
              console.log('You too time poor foo!');
              console.log("^That's a lot of double o's");
            }
          }}
        />
        <Counter
          count={world.spaceBalls}
          units="Space Balls"
          buttonText={
            <>
              {`+ 1 Space Balls`}
              <br />
              {`- ${howManyTimeBallsForASpaceBall} Time Balls`}
            </>
          }
          onClick={() => {
            if (world.timeBalls >= howManyTimeBallsForASpaceBall) {
              setWorld({
                ...world,
                timeBalls: world.timeBalls - howManyTimeBallsForASpaceBall,
                spaceBalls: world.spaceBalls + 1,
              });
            } else {
              console.log('Get more Time Balls foo!');
              console.log(
                '^They still free?  Sucks to be you!  Click, click, click...'
              );
            }
          }}
        />
      </Card>
    </Card>
  );
};

export default TimeBalls;

// TODO DREAM GAME:
// multiple users build a world by adding "recipes" that appear as nice blocks w a button to mix and it has a couple inputs and outputs and the world community votes on whether the contributed recipes are good and should be kept or if they're bad and should be remove or worse and ban the user; together we build a meritocratic world of virtual resource translation, deep creativity welcome once you understand and contribute to the backbone, irl sky is an example of the limitlessness of this emergent co-creation, let's juice this field
