import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getPrivatePosts } from '../../../api/privatePosts';
import AppContext from '../../AppContext';
import { millisFromUnixEpoch } from '../../shared/timestamp';
import { Post } from '../../../api/privatePosts';
import analyzePosts, { PostsAnalysis } from './analyzePosts';

// TODO janky default analysis hacks
const DEFAULT_ANALYSIS = { wordCountsByDay: {} } as PostsAnalysis;
const DEFAULT_POSTS = [] as Post[];
const DEFAULT_POSTS_LOADING = true;

const PrivatePostsContext = createContext({
  analysis: DEFAULT_ANALYSIS,
  posts: DEFAULT_POSTS,
  postsLoading: DEFAULT_POSTS_LOADING,
  reloadPosts: () => {},
});
export default PrivatePostsContext;

export const PrivatePostsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useContext(AppContext);
  // TODO use correct type here
  const [analysis, setAnalysis] = useState<PostsAnalysis>(DEFAULT_ANALYSIS);
  const [postsLoading, setPostsLoading] = useState(DEFAULT_POSTS_LOADING);
  const [posts, setPosts] = useState<Post[]>(DEFAULT_POSTS);

  const reloadPosts = useCallback(
    (callback = () => null) => {
      if (!user) return;
      setPostsLoading(true);
      // TODO need a User type
      //@ts-ignore
      getPrivatePosts({ uid: user.uid }, (data) => {
        const sortedPostsArray = Object.values(data).sort(
          (a, b) =>
            millisFromUnixEpoch(b.timestamp) - millisFromUnixEpoch(a.timestamp)
        );
        setPosts(sortedPostsArray);
        setPostsLoading(false);
        setAnalysis(analyzePosts(sortedPostsArray));
        callback && typeof callback === 'function' && callback();
      });
    },
    [user]
  );

  useEffect(() => {
    reloadPosts();
  }, [reloadPosts]);

  return (
    <PrivatePostsContext.Provider
      value={{
        analysis,
        posts,
        postsLoading,
        reloadPosts,
      }}
    >
      {children}
    </PrivatePostsContext.Provider>
  );
};
