import React, { useContext, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AppContext from '../../../../AppContext';
import ShowHideToggle from '../../../../shared/ShowHideToggle';
import Spinner from '../../../../shared/Spinner';
import { createPrivatePost } from '../../../../../api/privatePosts';
import ModalityButton from '../../ModalityButton';
import WordsNeeded from '../../WordsNeeded';
import {
  FullScreenButtons,
  FullScreenContentWrapper,
  FullScreenHide,
  FullScreenOnButton,
} from './FullScreen';
import ModalitySelect from './ModalitySelect';
import SentimentAnalysis from './SentimentAnalysis';
import WordCount from './WordCount';

const EMPTY_CONTENT = '';
const EMPTY_MODALITY_IDS = [];
const INITIAL_LOADING_STATE = false;

export const PostFormContext = React.createContext();

export const PostFormProvider = ({ children }) => {
  const [content, setContent] = useState(EMPTY_CONTENT);
  const [selectedModalityIds, setSelectedModalityIds] =
    useState(EMPTY_MODALITY_IDS);
  const [loading, setLoading] = useState(INITIAL_LOADING_STATE);

  const reset = () => {
    setLoading(false);
    setContent(EMPTY_CONTENT);
    setSelectedModalityIds(EMPTY_MODALITY_IDS);
  };

  const toggleSelectModality = (id) => {
    setSelectedModalityIds(
      selectedModalityIds.includes(id)
        ? selectedModalityIds.filter((modalityId) => modalityId !== id)
        : [...selectedModalityIds, id]
    );
  };

  return (
    <PostFormContext.Provider
      value={{
        content,
        loading,
        reset,
        selectedModalityIds,
        setContent,
        setLoading,
        toggleSelectModality,
      }}
    >
      {children}
    </PostFormContext.Provider>
  );
};

const PostForm = (props) => {
  const { user } = useContext(AppContext);
  const postFormContext = useContext(PostFormContext);
  if (!postFormContext)
    throw new Error(
      'PostForm requires PostFormContext: Did you forget to wrap an instance of PostForm in PostFormProvider?'
    );
  const {
    content,
    loading,
    reset,
    selectedModalityIds,
    setContent,
    setLoading,
    toggleSelectModality,
  } = postFormContext;

  const textarea = useRef();

  const onSubmit = (e) => {
    e.preventDefault();
    if (content === EMPTY_CONTENT) return;
    // TODO creating the api/database -compatible post object should be an app wide function eventually, but at least elevate it to a function here
    const post = { content };
    // TODO can use check against EMPTY_MODALITY_IDS here instead ?
    if (selectedModalityIds.length) {
      post.modalities = {};
      selectedModalityIds.forEach((id) => {
        // TODO 'name' is current convention but name|id|key should be resolved throughout codebase
        post.modalities[id] = { name: id };
      });
    }
    setLoading(true);
    createPrivatePost({ uid: user.uid, post }, () => {
      reset();
      // TODO is there an industry standard for the ordering here of reset and props.onSuccess ? what are the implications ?
      props.onSuccess();
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <FullScreenContentWrapper
        render={({ darkMode, fullScreen }) => (
          <>
            <FullScreenHide>
              <div className="my-2">
                <ShowHideToggle
                  label="Streaks"
                  localStorageKeyPrefix="private.posts.postform.streaks"
                >
                  <WordsNeeded />
                </ShowHideToggle>
              </div>
              <ShowHideToggle
                label="Select Modalities"
                localStorageKeyPrefix="private.posts.postform.modalityselect"
              >
                <ModalitySelect
                  disabled={loading}
                  selectedModalityIds={selectedModalityIds}
                  toggleSelect={toggleSelectModality}
                />
              </ShowHideToggle>
              <div className="my-2">
                {selectedModalityIds.length !== 0 &&
                  selectedModalityIds.map((modalityId) => (
                    <ModalityButton
                      key={modalityId}
                      modalityId={modalityId}
                      onClick={() => toggleSelectModality(modalityId)}
                    />
                  ))}
                <FullScreenOnButton
                  onSuccess={() => textarea.current.focus()}
                />
                <div style={{ clear: 'both' }}></div>
              </div>
            </FullScreenHide>
            <FullScreenButtons
              onClickSuccess={() => textarea.current.focus()}
            />
            <Form.Control
              as="textarea"
              ref={textarea}
              className={
                (darkMode && 'darkmode') + ' ' + (fullScreen && 'fullscreen')
              }
              rows={6}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              autoFocus={true}
              disabled={loading}
            />
            <FullScreenHide>
              <div className="my-2">
                {loading ? <Spinner /> : <Button type="submit">Post</Button>}
                <div className="float-end text-muted">
                  <span className="ms-4">
                    <WordCount content={content} />
                  </span>
                </div>
              </div>
              <ShowHideToggle
                label="Sentiment Analysis"
                localStorageKeyPrefix="private.posts.postform.sentimentanalysis"
              >
                <SentimentAnalysis content={content} />
              </ShowHideToggle>
            </FullScreenHide>
          </>
        )}
      />
    </Form>
  );
};
export default PostForm;
