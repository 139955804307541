import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import Card from '../../shared/Card';
import Posts from '../Posts';

const RoomDoesNotExist = () => (
  <Card title="Room Does Not Exist">
    <p>
      Want this room to exist?
      <br />
      Ask for it in <Link to="/r/general">r/general</Link>.
    </p>
  </Card>
);

const DEFAULT_ROOM_ID = 'home';
const Room = (props) => {
  const [room, setRoom] = useState(null);
  const { rooms, user } = useContext(AppContext);
  useEffect(() => {
    if (!rooms) return;
    const roomId = props.match.params.roomId || DEFAULT_ROOM_ID;
    setRoom(rooms[roomId]);
  }, [rooms]);
  useEffect(() => {
    if (room && room.title) document.title = `${room.title} | xBook`;
  }, [room]);
  if (!room) return <RoomDoesNotExist />;
  // TODO maybe more robust to use a room context instead of prop-drilling this room stuff
  return <Posts room={room} />;
};
export default withRouter(Room);
