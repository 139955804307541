import { Post } from '../../../api/privatePosts';
import countWords from '../../shared/countWords';
import { getDateStringForLocal, WordCountsByDay } from './shared';

// TODO improve organization and naming
// TODO tests

// https://stackoverflow.com/a/43855221/1438029
export function sameDay(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}
// https://stackoverflow.com/questions/1296358/how-to-subtract-days-from-a-plain-date#comment17476749_1296374
const getOneDayPrior = (date: Date) => {
  const temp = new Date(date);
  return new Date(temp.setDate(temp.getDate() - 1));
};
const dateIsOneDayPriorTo = (d1: Date, d2: Date) => {
  return sameDay(d1, getOneDayPrior(d2));
};

interface datesByWordCount {
  [wordCount: number]: Date;
}

/**
 * if yesterday, wordcount yesterday is the highest-word-count-since-date
 * if day before yesterday is higher than that count, increase the date for it
 * if not, then set a new lower highest-word-count-since-newdate
 * repeat to build a record of all x-or-more-words-since-date
 */
export const streakSinceDateByWordCount = (
  wordCountsByDay: WordCountsByDay,
  fromDate = new Date() // for easy testability
) => {
  // streaksSinceDateByWordCount
  const dateByWordCount: datesByWordCount = {};

  const fromDateDateString = getDateStringForLocal(fromDate);
  let prevDate = new Date(fromDateDateString);

  // assumes wordCountsByDay is already sorted in descending order from recent to older
  // crawl through wordCountsByDay from yesterday backwards in time
  for (const dateString in wordCountsByDay) {
    const date = new Date(dateString);
    const wordCount = wordCountsByDay[dateString];

    // if dateString is today, skip it and continue; do it later
    if (sameDay(date, prevDate)) continue;

    // if dateString is not 1 day prior to prevDate, we're done
    if (!dateIsOneDayPriorTo(date, prevDate)) break;

    const dateByWordCountIsEmpty = Object.keys(dateByWordCount).length === 0;
    const existingMinWordCount = Math.min(
      ...Object.keys(dateByWordCount).map(Number)
    );
    const wordCountIsHigherThanLowest = wordCount > existingMinWordCount;
    // if dateByWordCount is empty, start building it
    if (dateByWordCountIsEmpty) {
      dateByWordCount[wordCount] = date;
    }
    // or if this day and wordCount is higher and one-day-prior than the lowest/oldest
    // then set this as the new since date b/c this one's older
    else if (wordCountIsHigherThanLowest) {
      dateByWordCount[existingMinWordCount] = date;
    }
    // not empty and wordcount not high enough but we can set a new low
    else {
      // TODO this could be combined with the first if, but prefer comprehensibility atm
      dateByWordCount[wordCount] = date;
    }

    prevDate = date;
  }

  // handle fromDate
  const wordCount = wordCountsByDay[fromDateDateString];
  const existingMaxWordCount = Math.max(
    ...Object.keys(dateByWordCount).map(Number)
  );
  const fromDateWordCountIsHighest = wordCount > existingMaxWordCount;
  if (wordCount && fromDateWordCountIsHighest) {
    dateByWordCount[wordCount] = fromDate;
  }

  return dateByWordCount;
};

export interface PostsAnalysis {
  count: number;
  existingPostsWordCountToday: number;
  highestWordCount: number;
  streaks: datesByWordCount;
  totalWordCount: number;
  wordCountsByDay: WordCountsByDay;
}

const analyzePosts = (posts: Post[]): PostsAnalysis => {
  const sortedPosts = posts.sort(
    (a, b) => b.timestamp.getTime() - a.timestamp.getTime()
  );
  const { highestWordCount, totalWordCount, wordCountsByDay } = Object.entries(
    sortedPosts
  ).reduce(
    (accum, [key, post]) => {
      const postWordCount = countWords(post.content);
      accum.highestWordCount = Math.max(accum.highestWordCount, postWordCount);
      accum.totalWordCount += postWordCount;
      const dateString = getDateStringForLocal(post.timestamp);
      if (!accum.wordCountsByDay[dateString]) {
        accum.wordCountsByDay[dateString] = postWordCount;
      } else {
        accum.wordCountsByDay[dateString] += postWordCount;
      }
      return accum;
    },
    {
      highestWordCount: 0,
      totalWordCount: 0,
      wordCountsByDay: {} as WordCountsByDay,
    }
  );

  const count = posts.length;
  const existingPostsWordCountToday =
    wordCountsByDay[getDateStringForLocal(new Date())] || 0;
  const streaks = streakSinceDateByWordCount(wordCountsByDay);

  return {
    count,
    existingPostsWordCountToday,
    highestWordCount,
    streaks,
    totalWordCount,
    wordCountsByDay,
  };
};
export default analyzePosts;
