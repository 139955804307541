import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faLock } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import AppContext from '../../../AppContext';
import Card from '../../../shared/Card';
import { addUserToRoom, streamRoom, updateRoom } from './data';

const setRoomStarted = (id) => {
  if (!id) return;
  updateRoom(id, { started: true });
};

const JackboxRoom = (props) => {
  // @TODO don't need type in url
  // BETTER THOUGHT: likely good to have in url and use url, but change that the rooms do not have type as a property
  const { id, type } = props.match.params;
  const { user } = useContext(AppContext);
  const [room, setRoom] = useState({});
  const [username, setUsername] = useState('');

  useEffect(() => {
    streamRoom(id, (room) => setRoom(room));
  }, [id, user]);

  return (
    <Card loading={!room} title={type} titleMB0={true}>
      <div className="mb-3">
        <small>
          <Link to="/jackbox">&laquo; back to Jackbox Home</Link>
        </small>
      </div>
      {/* TODO make extremely shorter url to redirect to here */}
      <Card title="Share link:">{window.location.href}</Card>
      <Card
        title={<>Players ({room.users ? Object.keys(room.users).length : 0})</>}
      >
        <ul>
          {room.users
            ? Object.entries(room.users).map(([key, roomUser]) => (
                <li key={key}>
                  {roomUser.uid === user.uid ? (
                    <>
                      <strong>{roomUser.username}</strong> You're in!
                    </>
                  ) : (
                    roomUser.username
                  )}
                </li>
              ))
            : "No one's joined yet!"}
        </ul>
        {/* TODO this depends on room.users keys being equivalent to user uids, improve this: use uid in the room.users user objects */}
        {room.users && Object.keys(room.users).includes(user.uid) ? null : (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Control
              type="text"
              maxLength="20"
              style={{ width: '20em' }}
              placeholder="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              autoFocus={true}
            />
            {room && !room.started && (
              <Button
                onClick={() =>
                  addUserToRoom({ uid: user.uid, username, roomId: room.id })
                }
                disabled={!username}
                type="submit"
              >
                Join this game!
              </Button>
            )}
          </Form>
        )}
      </Card>
      <Card title="Play!">
        {room && !room.started && (
          // @TODO this started state needs to be passed through the server, not local state
          <Button onClick={() => setRoomStarted(room.id)}>
            Everyone's ready! <FontAwesomeIcon icon={faLock} /> Let's go!{' '}
            <FontAwesomeIcon icon={faFlag} />
          </Button>
        )}
        {room && room.started && <>Game has begun!</>}
      </Card>
    </Card>
  );
};
export default JackboxRoom;
