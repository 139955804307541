import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBug,
  faExclamationTriangle,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';

const WhiteStar = () => <>&#11088;</>;

function Icon({ icon, color }) {
  return <FontAwesomeIcon className="ms-2" icon={icon} style={{ color }} />;
}

export const Alpha = () => <Icon icon={faExclamationTriangle} color="red" />;
export const Beta = () => <Icon icon={faBug} color="lightgreen" />;
export const Favorite = () => <Icon icon={faHeart} color="green" />;
export const PremiumStar = WhiteStar;
