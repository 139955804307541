import Button from 'react-bootstrap/Button';

export function ButtonsMenu({
  changeMyPieceType,
  moveToOrigin,
}: {
  changeMyPieceType?: () => void;
  moveToOrigin?: () => void;
}) {
  return (
    <>
      <Button disabled={!changeMyPieceType} onClick={changeMyPieceType}>
        1. Toggle
        <br />
        <small>
          piece type
          <br />
          king/knight
        </small>
      </Button>
      <Button disabled>2</Button>
      <Button disabled>3</Button>
      <Button disabled>4</Button>
      <Button disabled>5</Button>
      <Button disabled>6</Button>
      <Button disabled>7</Button>
      <Button disabled>8</Button>
      <Button disabled>9</Button>
      <Button disabled={!moveToOrigin} onClick={moveToOrigin} bg="secondary">
        0. Warp
        <br />
        <small>
          to Origin
          <br />
          r0c0
        </small>
      </Button>
    </>
  );
}
