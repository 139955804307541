import { PieceTypeValueEnum } from './types';

export const moveIsValid = (
  piece: PieceTypeValueEnum,
  fromRowCol = [],
  toRowCol = []
) => {
  if (piece === 'king') {
    return (
      JSON.stringify(
        [
          Math.abs(fromRowCol[0] - toRowCol[0]),
          Math.abs(fromRowCol[1] - toRowCol[1]),
        ].sort()
      ) === JSON.stringify([0, 1])
    );
  }
  if (piece === 'knight') {
    if (
      JSON.stringify(
        [
          Math.abs(fromRowCol[0] - toRowCol[0]),
          Math.abs(fromRowCol[1] - toRowCol[1]),
        ].sort()
      ) === JSON.stringify([1, 2])
    ) {
      return true;
    }
    return false;
  }
};

export function throttle(func, timeFrame) {
  var lastTime = 0;
  return function (e) {
    var now = new Date();
    if (now - lastTime >= timeFrame) {
      func(e);
      lastTime = now;
    }
  };
}
