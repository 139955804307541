import { Ul } from './Shared';

function MapLegend() {
  return (
    <Ul.NoStyle>
      <li>
        <div
          style={{ display: 'inline-block', width: 16, height: 16 }}
          className="dirt me-2"
        />
        Dirt
      </li>
      <li>
        <div
          style={{ display: 'inline-block', width: 16, height: 16 }}
          className="grass me-2"
        />
        Grass
        <span className="text-muted">
          {' '}
          <small>
            <em>So much to explore!...</em>
          </small>
        </span>
      </li>
      <li>
        <div
          style={{ display: 'inline-block', width: 16, height: 16 }}
          className="sand me-2"
        />
        Sand
        <span className="text-muted">
          {' '}
          <small>
            <em>Something special about this stuff...</em>
          </small>
        </span>
      </li>
      <li>
        <div
          style={{ display: 'inline-block', width: 16, height: 16 }}
          className="water me-2"
        />
        Water{' '}
        <span className="text-muted">
          <small>
            <em>Jump in! The water's nice...</em> {/*(can be changed to land)*/}
          </small>
        </span>
      </li>
      <li>
        <div
          style={{ display: 'inline-block', width: 16, height: 16 }}
          className="deep-water me-2"
        />
        Deep Water
        <span className="text-muted">
          {' '}
          <small>
            <em>Ooh! Spooky...</em>
          </small>
        </span>
      </li>
    </Ul.NoStyle>
  );
}

export function Instructions() {
  return (
    <>
      {/* <h2>Instructions</h2> */}

      {/* TODO: crash course eg just king and first task; better: in-game tutorial, someday, this is AFTER implementation-specific brand-trash mortification has occurred on whatever product for whatever market, not for here in game engine level */}

      <h3>Piece Movement</h3>

      <ul>
        <li>
          <strong>Kings</strong>
          <ul>
            <li>
              move <strong>up-down-left-right</strong> like diagonally-impaired
              laterally-limited chess kings
            </li>
            <li>
              can move onto water, changes it to land,{' '}
              <em>water-erasing/land-paving</em>
              {/* ,{' '}<em>welping/canWaterwalk&&midasWaterToLand</em> */}
            </li>
          </ul>
        </li>
        <li>
          <strong>Knights</strong>
          <ul>
            <li>
              gall-hop <strong>arctan(1/2) wonk-from-lateral</strong> like chess
              knights
            </li>
          </ul>
        </li>
      </ul>

      <h3>User Controls</h3>

      <h4>Movement</h4>
      <ul>
        <li>
          <strong>WSAD keys</strong> or <strong>click</strong> to move{' '}
          <strong>up-down-left-right</strong>
          <br />
          <span className="text-muted">
            like a laterally-limited King when such a King
          </span>
        </li>
        <li>
          <strong>23-ED-XZ-AQ keys</strong> or <strong>click</strong> to{' '}
          <strong>as a chess Knight</strong>
          <br />
          <span className="text-muted">when such a Knight</span>
        </li>
      </ul>

      <h4>Actions</h4>
      <ul>
        <li>
          Digit <strong>1</strong> key to toggle between King or Knight
        </li>
        <li>
          Digit <strong>0</strong> key to warp to Origin (0,0)
        </li>
      </ul>

      <h3>Map</h3>

      <h4>Legend</h4>
      <MapLegend />
    </>
  );
}
