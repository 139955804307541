import DeleteButton from './DeleteButton';

export default function RelationshipsList({
  relationships,
  deleteRelationship,
}) {
  return Object.values(relationships)
    .sort((a, b) => a.selection.localeCompare(b.selection))
    .map((relationship) => (
      <div key={relationship.id}>
        <div>
          {`${relationship.selection} > ${relationship.options
            .filter((option) => option !== relationship.selection)
            .map((option) => option)}`}
          <DeleteButton
            onClick={() => deleteRelationship({ id: relationship.id })}
          />
        </div>
      </div>
    ));
}
