import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Posts } from '../../../../api/privatePosts';
import Card from '../../../shared/Card';
import { words } from '../../../shared/countWords';
import stopWords from '../../../shared/stopWords';

interface CountByWord {
  [word: string]: number;
}

const getCountByWord = (posts: Posts, options = { omitStopWords: false }) => {
  const { omitStopWords } = options;
  const result: CountByWord = {};
  // let longestWord = '';
  Object.values(posts).forEach((post) =>
    words(post.content).forEach((word: string) => {
      if (omitStopWords && stopWords.includes(word.toLowerCase())) return;
      // longestWord = word.length > longestWord.length ? word : longestWord;
      result[word] = result[word] ? result[word] + 1 : 1;
    })
  );
  // console.log(longestWord);
  // console.log(countByWord);
  return result;
};

const getSortedCountByWordArray = (
  countByWord: CountByWord,
  options = { limit: 0 }
) =>
  Object.entries(countByWord)
    .sort(([aWord, aCount], [bWord, bCount]) =>
      bCount === aCount ? aWord.localeCompare(bWord) : bCount - aCount
    )
    .slice(0, options.limit || undefined);

const LIMIT_AMOUNT = 20;
const PostsAnalysis = ({ posts }: { posts: Posts }) => {
  const [omitStopWords, setOmitStopWords] = useState(true);
  const [doLimit, setDoLimit] = useState(true);

  const countByWord = getCountByWord(posts, { omitStopWords });
  const sortedCountByWordArray = getSortedCountByWordArray(countByWord, {
    limit: doLimit ? LIMIT_AMOUNT : 0,
  });

  return (
    <Card title="Posts Analysis">
      <div className="my-2">
        <span className="me-2">
          <ButtonGroup>
            <ToggleButton
              id="toggle-omitStopWords"
              type="checkbox"
              variant="outline-primary"
              checked={omitStopWords}
              value="omitStopWords"
              onChange={(e) => setOmitStopWords(e.currentTarget.checked)}
            >
              Omit Stop Words
            </ToggleButton>
            <Button
              disabled={true}
              variant={omitStopWords ? 'success' : 'danger'}
            >
              {omitStopWords ? 'On' : 'Off'}
            </Button>
          </ButtonGroup>
        </span>
        <span className="me-2">
          <ButtonGroup>
            <ToggleButton
              id="toggle-doLimit"
              type="checkbox"
              variant="outline-primary"
              checked={doLimit}
              value="doLimit"
              onChange={(e) => setDoLimit(e.currentTarget.checked)}
            >
              Limit {LIMIT_AMOUNT}
            </ToggleButton>
            <Button disabled={true} variant={doLimit ? 'success' : 'danger'}>
              {doLimit ? 'On' : 'Off'}
            </Button>
          </ButtonGroup>
        </span>
      </div>
      <h6>Most Frequently Used Words</h6>
      <Card>
        {sortedCountByWordArray
          .map((wordAndCount) => wordAndCount[0])
          .join(', ')}
      </Card>
      <Table>
        <thead>
          <tr>
            <th>Word</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {sortedCountByWordArray.map((wordAndCount) => {
            const [word, count] = wordAndCount;
            return (
              <tr key={word}>
                <td style={{ maxWidth: 0 }}>{word}</td>
                <td>{count}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
};
export default PostsAnalysis;
