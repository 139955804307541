import { Component, useContext, useEffect, useMemo, useState } from 'react';
import AppContext, { UserType } from '../../../AppContext';
import Card from '../../../shared/Card';
import Spinner from '../../../shared/Spinner';
import { moveIsValid, throttle } from './utils';
import { CellsType, ChessWorldUserType } from './types';
import Board, { getAdjacency } from './Board';
import './index.css';
import { Cell } from './Cell';
import { Instructions } from './Instructions';
import {
  cellsRef,
  increment,
  increment1,
  makeDirt,
  makeGrass,
  userRef,
} from './api';
import { ResetWorldButton } from './ResetWorldButton';
import { MiniMap, MiniMapRange } from './MiniMap';
import { GameUser } from './GameUser';
import { rowColChangeFromKeydownEventCodeAndPiece } from './movement';
import { ButtonsMenu } from './ButtonsMenu';

function percentChance(percent) {
  return Math.random() < percent;
}

function spawnTree() {
  return percentChance(0.01);
}

const BOARD_RADIUS = 4; // TODO technically a VISIBLE_RADIUS specifically for that map/view it's applied to
const ONKEYDOWN_THROTTLE_TIME_MS = 100;

// TODO try catch api error protection
class ChessWorld extends Component<
  {},
  {
    cells: any[];
    gameUser: ChessWorldUserType;
    loading: boolean;
    readyForAnotherMove: boolean;
    user: UserType;
  }
> {
  constructor(props) {
    const { cells, gameUser, loading, readyForAnotherMove, user } = props;
    super(props);
    this.state = {
      cells,
      gameUser,
      loading,
      readyForAnotherMove,
      user,
    };
    this.changeMyPieceType = this.changeMyPieceType.bind(this);
    this.moveTo = this.moveTo.bind(this);
    this.myPieceType = this.myPieceType.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
    this.onKeydownThrottled = throttle(
      this.onKeydown,
      ONKEYDOWN_THROTTLE_TIME_MS
    );
    this.readyToMove = this.readyToMove.bind(this);
    this.moveToOrigin = this.moveToOrigin.bind(this);
    this.getCell = this.getCell.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { cells, gameUser, loading, readyForAnotherMove, user } =
        this.props;
      this.setState({
        cells,
        gameUser,
        loading,
        readyForAnotherMove,
        user,
      });
    }
  }

  getRowColOfMyPiece = () => this.state.gameUser?.position || [0, 0];

  // TODO clean this up; is it even effective? what's it do?
  readyToMove = () => this.state.readyForAnotherMove && !this.state.loading;

  // TODO use gameUser.piece instead of this
  myPieceType() {
    const [myRow, myCol] = this.getRowColOfMyPiece();
    const cell = this.getCell(myRow, myCol);
    const userId = this.state.user?.uid;
    const myPiece =
      cell &&
      cell.pieces &&
      Object.keys(cell.pieces).includes(userId) &&
      cell.pieces[userId] &&
      cell.pieces[userId].piece;
    return myPiece;
  }

  // TODO this is broken now, probably just the readyToMove check
  changeMyPieceType() {
    // if (!this.readyToMove()) {
    //   return;
    // }

    const [myRow, myCol] = this.getRowColOfMyPiece();

    if (!Number.isInteger(myRow) || !Number.isInteger(myCol)) {
      // TODO must at least let users know if not handle this more gracefully
      console.log(
        "Can't find your piece!  Try clicking 'Return to Origin' Button..."
      );
      return;
    }

    this.setState({ readyForAnotherMove: false });
    const updates = {};

    const newPiece = this.myPieceType() === 'knight' ? 'king' : 'knight';

    if (Number.isInteger(myRow) && Number.isInteger(myCol)) {
      updates[`/${myRow}/${myCol}/pieces/${this.state.user?.uid}`] = {
        piece: newPiece,
      };
    }
    cellsRef().update(updates);
  }

  // TODO moving is stuck/broken if multiple windows are open
  /**
   *
   * canMoveToWater
   * - TODO:
   *   - toggled on/off by outside states like user preference/setting or toggle
   *   - like an in-game temporary "water-erasing/land-paving" effect
   */
  // TODO this is huge, get this OUT of this class component god damn [c'mon co-pilot, we've waiting...] it
  moveTo(
    row: number,
    col: number,
    { canMoveToWater, overrideInvalidMove } = {
      canMoveToWater: false,
      overrideInvalidMove: false,
    }
  ) {
    // TODO fix
    // if (!this.readyToMove()) {
    //   return;
    // }

    const [myRow, myCol] = this.getRowColOfMyPiece();

    if (!overrideInvalidMove) {
      if (!Number.isInteger(myRow) || !Number.isInteger(myCol)) {
        // TODO must at least let users know if not handle this more gracefully
        console.error(
          "Can't find your piece!  Try clicking 'Return to Origin' Button..."
        );
        return;
      }
      if (!moveIsValid(this.myPieceType(), [myRow, myCol], [row, col])) {
        console.error('Invalid move!');
        return;
      }
      const isWater =
        this.state.cells[row] && !this.state.cells[row][col]?.touched;
      if (!canMoveToWater && isWater) {
        console.error('Cannot move to water!');
        return;
      }
    }

    if (myRow === row && myCol === col) {
      console.error(
        'Failed attempt move to current cell (prevents hanging in self-contradictory transaction)'
      );
      return;
    }

    /**
     * Move is:
     * - valid
     * - from myRow, myCol to row, col
     *
     * Proceed with move.
     */

    // TODO: / { "isMoving": true }
    this.setState({ readyForAnotherMove: false });

    if (
      (this.state.cells[row] && !this.state.cells[row][col]) ||
      (this.state.cells[row] && !this.state.cells[row][col].touched)
    ) {
      userRef(this.state.user?.uid).update({ water: increment1 });
    }

    const updates = {};
    if (Number.isInteger(myRow) && Number.isInteger(myCol)) {
      updates[`/${myRow}/${myCol}/pieces/${this.state.user?.uid}`] = null;
    }
    updates[`/${row}/${col}/pieces/${this.state.user?.uid}`] = {
      piece: this.myPieceType(),
    };
    updates[`/${row}/${col}/touched`] = true;
    cellsRef().update(updates);

    if (this.state.cells[row] && this.state.cells[row][col]?.trees) {
      userRef(this.state.user?.uid).update({
        apples: increment(this.state.cells[row][col].trees),
      });
    }

    if (spawnTree()) {
      const updates = {};
      updates[`/${row}/${col}/trees`] = increment1;
      cellsRef().update(updates);
    }

    userRef(this.state.user?.uid).update({
      moves: increment1,
      position: [row, col],
    });
  }

  moveToOrigin() {
    this.moveTo(0, 0, { overrideInvalidMove: true });
  }

  onKeydown = (event) => {
    console.log('onKeydown event.code:', event.code);
    // TODO fix
    // if (!this.readyToMove()) {
    //   return;
    // }

    const [myRow, myCol] = this.getRowColOfMyPiece();

    if (event.code === 'Digit1') {
      event.preventDefault();
      this.changeMyPieceType();
    }

    if (event.code === 'Digit0') {
      event.preventDefault();
      this.moveToOrigin();
    }

    if (event.code === 'Space') {
      event.preventDefault();
      if (this.myPieceType() === 'king') {
        makeGrass([myRow, myCol]);
      }
      if (this.myPieceType() === 'knight') {
        makeDirt([myRow, myCol]);
      }
    }

    /**
     * Moving with Keys like WASD and Knight Keys etc
     */
    const rowColChange = rowColChangeFromKeydownEventCodeAndPiece(
      event.code,
      this.myPieceType()
    );
    if (
      rowColChange !== null &&
      typeof rowColChange === 'object' &&
      typeof rowColChange[Symbol.iterator] === 'function'
    ) {
      const [rowChange, colChange] = rowColChange;
      const [newRow, newCol] = [myRow + rowChange, myCol + colChange];
      if (typeof rowChange === 'number' && typeof colChange === 'number') {
        this.moveTo(newRow, newCol, {
          canMoveToWater: this.myPieceType() === 'king',
        });
        // if (this.myPieceType() === 'king') {
        //   makeGrass([newRow, newCol]);
        // }
        // if (this.myPieceType() === 'knight') {
        //   makeDirt([newRow, newCol]);
        // }
      }
    } else {
      console.warn('Invalid keydown event code:', event.code);
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeydownThrottled);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeydownThrottled);
  }

  getCell(row, col) {
    return (
      this.state.cells && this.state.cells[row] && this.state.cells[row][col]
    );
  }

  render() {
    const [myRow, myCol] = this.getRowColOfMyPiece();
    // TODO fix or eliminate
    // const pieceColor = this.readyToMove() ? 'green' : 'red';
    const pieceColor = 'inherit';
    // const MINIMAP_RADIUS = 20;
    // const DEFAULT_MINIMAP_RADIUS = 20;

    // TODO absurd that this even exists, let alone here, this is a deepest tangle, stinkiest spaghetti
    function myPieceFromCell(cell, userId: string) {
      const myPiece =
        cell &&
        cell.pieces &&
        Object.keys(cell.pieces).includes(userId) &&
        cell.pieces[userId] &&
        cell.pieces[userId].piece;
      return myPiece;
    }

    return (
      <Card id="cultivation">
        <h1>Cultivation</h1>
        {/* <h2 className="text-muted">WeBuildWorldsTM</h2> // @webuildworlds (.com is taken) */}
        <Card>
          <Instructions />
        </Card>
        <Card>
          {/* <div className="my-3">
            <MiniMapRange
              cells={this.state.cells}
              highestRow={myRow + MINIMAP_RADIUS}
              lowestRow={myRow - MINIMAP_RADIUS}
              highestCol={myCol + MINIMAP_RADIUS}
              lowestCol={myCol - MINIMAP_RADIUS}
            />
          </div> */}
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
              <ButtonsMenu
                changeMyPieceType={this.changeMyPieceType}
                moveToOrigin={this.moveToOrigin}
              />
            </div>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Board
                center={{ row: myRow, col: myCol }}
                radius={BOARD_RADIUS}
                renderCell={(row, col) => {
                  const key = row + ',' + col;
                  // TODO this should all be in Cell component
                  const handleClick = () => {
                    this.moveTo(row, col);
                  };
                  // TODO fix
                  const loading = false;
                  //   row === myRow &&
                  //   col === myCol &&
                  //   !this.state.readyForAnotherMove;
                  // console.log(row, myRow, col, myCol, this.readyToMove());

                  const cell = this.getCell(row, col);
                  const userId = this.state.user?.uid;
                  const myPiece = myPieceFromCell(cell, userId);
                  const [isAdjacentToLand, isAdjacentToWater] = getAdjacency({
                    getCell: this.getCell,
                    row,
                    col,
                  });

                  return (
                    <Cell
                      cell={cell}
                      isAdjacentToLand={isAdjacentToLand}
                      isAdjacentToWater={isAdjacentToWater}
                      key={key}
                      loading={loading}
                      myPiece={myPiece}
                      onClick={handleClick}
                      pieceColor={pieceColor}
                      text={`r${row}c${col}`}
                    />
                  );
                }}
              />
              <GameUser
                water={this.state.gameUser?.water}
                apples={this.state.gameUser?.apples}
                moves={this.state.gameUser?.moves}
                myRow={myRow}
                myCol={myCol}
              />
            </div>
          </div>
        </Card>
        {this.state.user?.admin && (
          <Card bg="danger">
            <h2>Admin</h2>
            <h3 className="text-muted">(only visible to user's with admin)</h3>
            <ResetWorldButton />
          </Card>
        )}
      </Card>
    );
  }
}

function useIncrementMap() {
  const [state, setState] = useState({});

  useEffect(() => {
    console.log(state);
  }, [state]);

  function _increment(key) {
    setState({ [key]: (state[key] || 0) + 1 });
  }

  return _increment;
}

// TODO convert ChessWorld into this ChessWorldExport function component please
export default function ChessWorldExport(props) {
  const { user } = useContext(AppContext);
  const [gameUser, setGameUser] = useState<ChessWorldUserType>(null);
  const [cells, setCells] = useState<CellsType>(null);

  // TODO what is this and why
  const [readyForAnotherMove, setReadyForAnotherMove] =
    useState<boolean>(false);

  useEffect(() => {
    cellsRef().on('value', (snapshot) => {
      setCells(snapshot.val());
    });
  }, []);

  useEffect(() => {
    userRef(user?.uid).on('value', (snapshot) => {
      setGameUser(snapshot.val());
    });
  }, [user]);

  useEffect(() => {
    if (!user) return;
    userRef(user.uid).update({
      lastLogin: new Date().toISOString(),
    });
  }, [user]);

  async function updateCellPieces(row = 0, col = 0, pieces) {
    const updates = {};
    updates[`/${row}/${col}/pieces`] = pieces;
    await cellsRef().update(updates);
  }

  async function updateUserPosition(row = 0, col = 0) {
    const cellPieces = (cells[row] && cells[row][col]?.pieces) || {};
    cellPieces[user.uid] = { piece: 'king' };
    userRef(user.uid).update({ position: [row, col] });
    updateCellPieces(row, col, cellPieces);
  }

  useEffect(() => {
    if (gameUser && !gameUser.position) {
      updateUserPosition();
      return;
    }
  }, [cells]);

  useEffect(() => {
    if (gameUser?.position && cells && user.uid) {
      const [row, col] = gameUser.position;
      if (
        !cells[row] ||
        !cells[row][col] ||
        !cells[row][col].pieces ||
        !cells[row][col].pieces[user.uid]
      ) {
        updateUserPosition();
      }
    }
  }, [cells, gameUser, user]);

  const loading = useMemo(() => !cells || !gameUser, [cells, gameUser]);

  if (loading) return <Spinner />;

  return (
    <ChessWorld
      cells={cells}
      readyForAnotherMove={readyForAnotherMove}
      setReadyForAnotherMove={setReadyForAnotherMove}
      user={user}
      gameUser={gameUser}
      {...props}
    />
  );
}
