import RBCard from 'react-bootstrap/card';

const Card = ({
  children,
  loading = false,
  titleMB0 = false,
  title = '',
  ...props
}) => (
  <RBCard className="mb-3" {...props}>
    <RBCard.Body>
      {title && (
        <RBCard.Title className={titleMB0 && 'mb-0'}>{title}</RBCard.Title>
      )}
      {children}
    </RBCard.Body>
  </RBCard>
);

export default Card;
