import React, { useEffect, useState } from 'react';
var Sentiment = require('sentiment');

// TODO this is a heavy calculation; optimize and debounce or throttle, etc
const WordsByScore = ({
  calculation = [],
}: {
  calculation: { [word: string]: number }[];
}) => {
  const wordsByScore = {
    '5': [],
    '4': [],
    '3': [],
    '2': [],
    '1': [],
    '-1': [],
    '-2': [],
    '-3': [],
    '-4': [],
    '-5': [],
  } as { [score: string]: string[] };
  calculation.forEach((wordScore) => {
    const [word, score] = Object.entries(wordScore)[0];
    wordsByScore[score.toString()].push(word);
  });
  return (
    <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
      {['5', '4', '3', '2', '1', '-1', '-2', '-3', '-4', '-5'].map((score) => (
        <li key={score}>
          <div
            style={{
              display: 'inline-block',
              textAlign: 'right',
              width: '1.5rem',
            }}
          >
            {score}:
          </div>{' '}
          {wordsByScore[score].join(', ')}
        </li>
      ))}
    </ul>
  );
};

interface SentimentAnalyzeResults {
  calculation: any;
  comparative: number;
  negative: string[];
  positive: string[];
  score: number;
  tokens: string[];
  words: string[];
}

// this outer scope constant will only be assigned once; should be fine
// use a ref in component if need mutable value bound to component instance
const sentiment = new Sentiment();

const SentimentAnalysis = ({ content }: { content: string }) => {
  const [analysis, setAnalysis] = useState({} as SentimentAnalyzeResults);

  useEffect(() => {
    setAnalysis({ ...sentiment.analyze(content) });
  }, [content]);

  const { calculation, comparative, negative, positive, score } = analysis;

  return (
    <>
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
        <li>Score: {score}</li>
        <li>Comparative: {Math.round(comparative * 1000) / 1000}</li>
        <li>
          Positive:{' '}
          {Array.isArray(positive) && [...new Set(positive)].join(', ')}
        </li>
        <li>
          Negative:{' '}
          {Array.isArray(negative) && [...new Set(negative)].join(', ')}
        </li>
      </ul>
      <WordsByScore calculation={calculation} />
    </>
  );
};
export default SentimentAnalysis;
