import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppProvider } from '../AppContext';
import Routes from '../Routes';
import AppContainer from './AppContainer';
import AppHeader from './AppHeader';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
  render() {
    return (
      <AppProvider>
        <BrowserRouter>
          <AppContainer>
            <ToastContainer />
            <AppHeader />
            <div className="mt-3">
              <Routes />
            </div>
          </AppContainer>
        </BrowserRouter>
      </AppProvider>
    );
  }
}

export default App;
