import Card from '../../../shared/Card';
import { Ul } from './Shared';

export function GameUser({ water, apples, moves, myRow, myCol }) {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        gap: '0.25rem',
      }}
    >
      <Card>
        <h3>Inventory</h3>
        <Ul.NoStyle>
          <li>
            <strong>Apples:</strong> {apples}
          </li>
          <li>
            <strong>Water:</strong> {water}
          </li>
        </Ul.NoStyle>
      </Card>
      <Card>
        <h3>Data</h3>
        <Ul.NoStyle>
          <li>
            <strong>Moves:</strong> {moves}
          </li>
        </Ul.NoStyle>
      </Card>
      <Card>
        <h3>Debug</h3>
        <Ul.NoStyle>
          <li>
            {/* TODO this is abuse; convert all siblings of immediate container&&p to li */}
            <span className="text-muted">
              r{myRow}c{myCol}
            </span>
          </li>
          <li>
            {/* TODO this is abuse; convert all br-siblings of strong and span here to li */}
            <strong>Row:</strong> {myRow}
          </li>
          <li>
            <strong>Col:</strong> {myCol}
          </li>
          {/* <li>readyToMove: {this.readyToMove()?.toString()}</li> */}
        </Ul.NoStyle>
      </Card>
    </div>
  );
}
