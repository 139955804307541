import React from 'react';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from '../../../../../shared/Card';
import { updateGame } from '../../api';

export default function Settings({ game, gameId }) {
  if (!game?.settings) {
    return null;
  }

  const {
    alwaysShowWords,
    assassins,
    blues,
    cols,
    includeEmojis,
    yellows,
    reds,
    rows,
    wordsPerCard,
  } = game.settings;
  // TODO this should be inverted; total cards should be more primary than rows or cols
  const totalCards = rows * cols;

  function setGameSetting(key: string, value: any) {
    const newGame = { ...game };
    newGame.settings[key] = value;
    updateGame(gameId, newGame);
  }

  function onChangeAlwaysShowWords(e: React.ChangeEvent<HTMLInputElement>) {
    setGameSetting('alwaysShowWords', e.target.checked);
  }

  function onChangeAssassins(e: React.ChangeEvent<HTMLInputElement>) {
    const newAssassins = Number(e.target.value);
    const newYellows = totalCards - reds - blues - newAssassins;
    if (newAssassins >= 0 && newYellows >= 0) {
      setGameSetting('assassins', newAssassins);
      setGameSetting('yellows', newYellows);
    }
  }

  function onChangeIncludeEmojis(e: React.ChangeEvent<HTMLInputElement>) {
    setGameSetting('includeEmojis', e.target.checked);
  }

  function onChangeWordsPerCard(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    if (value > 0) {
      setGameSetting('wordsPerCard', value);
    }
  }

  return (
    <Card title="Settings">
      <ListGroup className="mb-2">
        <ListGroup.Item>
          <strong>Cards:</strong> {totalCards}
        </ListGroup.Item>
      </ListGroup>
      <ListGroup className="mb-2">
        <ListGroup.Item>
          <strong>Reds:</strong> {reds}
        </ListGroup.Item>
        <ListGroup.Item>
          <strong>Blues:</strong> {blues}
        </ListGroup.Item>
        <ListGroup.Item>
          <strong>Assassins:</strong>
          <Form.Control
            type="number"
            value={assassins}
            onChange={onChangeAssassins}
            disabled={game?.phase !== 'setup'}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <strong>Yellows:</strong> {yellows}
        </ListGroup.Item>
      </ListGroup>
      <ListGroup className="mb-2">
        <ListGroup.Item>
          <Form.Label>
            <strong>Words Per Card:</strong>
          </Form.Label>
          <Form.Control
            type="number"
            value={wordsPerCard}
            onChange={onChangeWordsPerCard}
            disabled={game?.phase !== 'setup'}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <Form.Label>
            <strong>Emojis:</strong>
          </Form.Label>
          <Form.Check
            // TODO make this work
            type="switch"
            value={includeEmojis}
            onChange={onChangeIncludeEmojis}
            disabled={true || game?.phase !== 'setup'}
          />
        </ListGroup.Item>
      </ListGroup>
      <ListGroup>
        {/* <ListGroup.Item>
          <strong>Rows:</strong> {rows}
        </ListGroup.Item>
        <ListGroup.Item>
          <strong>Cols:</strong> {cols}
        </ListGroup.Item> */}
        <ListGroup.Item>
          <strong>Always Show Words:</strong>{' '}
          <Form.Check
            // TODO make this work
            disabled={true}
            type="switch"
            value={alwaysShowWords}
            onChange={onChangeAlwaysShowWords}
          />
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}
