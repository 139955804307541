/**
 * You've reached the current usage cap for GPT-4. You can continue with the default model now, or try again after 7:36 AM.
 */

import React, { useState, CSSProperties } from 'react';

enum Player {
  None = 'none',
  One = 'one',
  Two = 'two',
}

const initializeBoard = (): Player[][] => {
  return Array(3)
    .fill(null)
    .map(() => Array(3).fill(Player.None));
};

const ChessGPTv1 = () => {
  const [board, setBoard] = useState(initializeBoard);
  const [currentPlayer, setCurrentPlayer] = useState(Player.One);
  const [gameWinner, setGameWinner] = useState(Player.None);

  const checkForWinner = (board: Player[][]): Player => {
    // Check rows
    for (let i = 0; i < 3; i++) {
      if (
        board[i][0] !== Player.None &&
        board[i][0] === board[i][1] &&
        board[i][0] === board[i][2]
      ) {
        return board[i][0];
      }
    }

    // Check columns
    for (let i = 0; i < 3; i++) {
      if (
        board[0][i] !== Player.None &&
        board[0][i] === board[1][i] &&
        board[0][i] === board[2][i]
      ) {
        return board[0][i];
      }
    }

    // Check diagonals
    if (
      board[0][0] !== Player.None &&
      board[0][0] === board[1][1] &&
      board[0][0] === board[2][2]
    ) {
      return board[0][0];
    }
    if (
      board[0][2] !== Player.None &&
      board[0][2] === board[1][1] &&
      board[0][2] === board[2][0]
    ) {
      return board[0][2];
    }

    return Player.None;
  };

  const handleCellClick = (x: number, y: number) => {
    if (board[x][y] !== Player.None || gameWinner !== Player.None) return;

    const newBoard = board.map((row) => [...row]);
    newBoard[x][y] = currentPlayer;
    setBoard(newBoard);

    const winner = checkForWinner(newBoard);
    if (winner !== Player.None) {
      setGameWinner(winner);
    } else {
      setCurrentPlayer(currentPlayer === Player.One ? Player.Two : Player.One);
    }
  };

  const getCellStyle = (cell: Player): CSSProperties => {
    switch (cell) {
      case Player.One:
        return { color: 'blue' };
      case Player.Two:
        return { color: 'red' };
      default:
        return {};
    }
  };

  return (
    <div className="App">
      <div>
        {board.map((row, x) => (
          <div key={x}>
            {row.map((cell, y) => (
              <button
                key={y}
                style={getCellStyle(cell)}
                onClick={() => handleCellClick(x, y)}
              >
                {cell !== Player.None ? cell : ''}
              </button>
            ))}
          </div>
        ))}
      </div>
      {gameWinner !== Player.None ? (
        <div>Winner: {gameWinner}</div>
      ) : (
        <div>Current Player: {currentPlayer}</div>
      )}
    </div>
  );
};

export default ChessGPTv1;
