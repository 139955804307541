import firebase from '../components/firebase.js';
import { postRef } from './posts';
import uidMentions from './uidMentions';
import { getUsers } from './users';

const notificationUserPostRef = ({ uid, postId }) =>
  firebase.database().ref('notifications/' + uid + '/' + postId);
const notificationsRef = ({ uid }) =>
  firebase.database().ref('notifications/' + uid);

// post w id postId received a reply
// add a notification for the user who authored the post
export const addNotificationsDeprecated = ({ postId, uid }) => {
  postRef(postId)
    .once('value')
    .then((snapshot) => {
      const post = snapshot.val();
      const postUserId = post.userId;
      if (postUserId === uid) {
        // do not notify user of post from themselves
        return;
      }
      notificationUserPostRef({ uid: postUserId, postId }).update({
        [uid]: firebase.database.ServerValue.TIMESTAMP,
      });
    });
};

// add notification for uid with link to postId
const addNotification = ({ uid, postId, type, fromUid }) => {
  notificationUserPostRef({ uid, postId }).update({
    [uid]: {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      type,
      uid: fromUid,
    },
  });
};

// TODO get rid of old count notifiations and should always require userId here
export const removeNotification = ({ postId, uid, userId = null }) => {
  if (userId) {
    notificationsRef({ uid }).child(postId).child(userId).remove();
  } else {
    notificationsRef({ uid }).child(postId).remove();
  }
};

export const addNotificationsForMentionsIfDoesntAlreadyExist = ({
  postContent,
  postId,
  myUserId,
}) => {
  const uids = uidMentions(postContent);
  if (uids && uids.length > 0) {
    getUsers((users) => {
      Object.values(users).forEach((user) => {
        const { uid } = user;
        if (uids.includes(uid)) {
          addNotification({ uid, postId, type: 'mention', fromUid: myUserId });
        }
      });
    });
  }
};
