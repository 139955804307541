import { useEffect, useState } from 'react';
import { getRandomArrayElements } from '../../Games/Lobby/Games/CodeNames/utils';

const OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

function useAi() {
  const [data, setData] = useState([]);
  const [neurons, setNeurons] = useState<{}>([]);
  const [synapses, setSynapses] = useState<{}>([]);

  // useEffect(() => {
  //   setNeurons([
  //     { f: (x, y) => x + y },
  //     { f: (x, y) => x * y },
  //     { f: (x, y) => x % y },
  //   ]);
  //   setSynapses([
  //     {
  //       from: [-1, 0],
  //       to: [],
  //     },
  //   ]);
  // }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  function askAi(arr) {
    // TODO this is where the magic happens
    // TODO actually the magic happens behind the scenes in crunching learning somehow, but this reveals
    return null;
  }

  function tellAi(val) {
    setData([...data, val]);
  }

  return { askAi, tellAi };
}

export default function AI() {
  const [options, setOptions] = useState([]);
  const { askAi, tellAi } = useAi();

  function refreshOptions() {
    const newOptions = getRandomArrayElements(OPTIONS, 2);
    setOptions(newOptions);
    askAi(newOptions);
  }

  useEffect(() => {
    refreshOptions();
  }, []);

  function handleSelectOption(val) {
    tellAi(val);
    refreshOptions();
  }

  return (
    <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
      {options.map((option) => (
        <button
          onClick={() => handleSelectOption(option)}
          style={{ borderRadius: '1rem', height: '3rem', width: '4rem' }}
        >
          {option}
        </button>
      ))}
    </div>
  );
}
