import LinkifyJS from 'linkifyjs/react';

const Linkify = ({ children }) => (
  <LinkifyJS
    options={{
      defaultProtocol: 'https',
      target: {
        url: (url) => (url.startsWith('https://xbk.io') ? '_self' : '_blank'),
      },
    }}
  >
    {children}
  </LinkifyJS>
);
export default Linkify;
