export const HABIT_TYPES = Object.freeze({
  BAD: 'BAD',
  BASIC: 'BASIC',
  GOOD: 'GOOD',
});

export const STATUS = Object.freeze({
  DONE: 'DONE',
});

export const DEFAULT_HABITS = {
  tobacco: { title: 'tobacco', count: 0, type: HABIT_TYPES.BAD },
  marijuana: { title: 'marijuana', count: 0, type: HABIT_TYPES.BAD },
  eat: { title: 'eat', count: 0, type: HABIT_TYPES.BASIC },
  'vehicle needs': {
    title: 'vehicle needs',
    count: 0,
    type: HABIT_TYPES.BASIC,
  },
  pom20: { title: 'pom20+s', count: 0, type: HABIT_TYPES.GOOD },
  'workout for pain-freedom, physical function, vitality, and healthy self-image':
    {
      title:
        'workout for pain-freedom, physical function, vitality, and healthy self-image',
      count: 0,
      type: HABIT_TYPES.GOOD,
    },
  'chores and errands': {
    title: 'chores and errands',
    count: 0,
    type: HABIT_TYPES.GOOD,
  },
  'oral care': { title: 'oral care', count: 0, type: HABIT_TYPES.GOOD },
  walk: { title: 'walk', count: 0, type: HABIT_TYPES.GOOD },
  write: { title: 'write', count: 0 },
  'respond to messages': { title: 'respond to messages', count: 0 },
};

// @TODO code should handle even if habit object is empty
export const DEFAULT_NEW_HABIT = { title: '', count: 0 };
