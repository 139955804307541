import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import DailySchedule from './DailySchedule';

/**
 * 1. basic
 * @todo persist options to db (for user and/or eventually the collective)
 * @todo add/edit/remove options from db
 *
 * 2. intelligence
 * @todo intel on up/down vote of option esp after complete or as correlate to done/skip
 * @todo use user data and analysis to optimize non-random recommendations
 *
 * 3. more
 * @todo feedback notes for options eg text response
 * @todo more appified lean interface, tabs/button-to-pages for options, history etc
 * @todo timestamps on history
 * @todo timer tools
 * @todo socialized eg comments and sharing
 *
 */

const options = [
  {
    title: 'meditate',
    description: 'silent seated eyes-closed present somatic breathe (3 min)',
  },
  {
    title: 'smile',
    description:
      'w your face, your eyes, your heart and body and soul glowing open (1 min)',
  },
  {
    title: 'todo',
    description:
      'complete something from your todo list now or spend at least 20 minutes focusing making progress on it',
  },
  {
    title: 'body',
    description:
      'breathe deeply, move your core hips and back, warmup respectfully (5 min)',
  },
];
const getRandomOption = () =>
  options[Math.floor(Math.random() * options.length)];

const Option = ({ option }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{option.title}</Card.Title>
        <Card.Text>{option.description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const History = ({ options }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>title</th>
          <th>description</th>
          <th>status</th>
        </tr>
      </thead>
      <tbody>
        {[...options].reverse().map((option) => (
          <tr
            class={option.status === 'done' ? 'table-success' : 'table-danger'}
          >
            <td>{option.title}</td>
            <td>{option.description}</td>
            <td>{option.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const DoNow = () => {
  const [option, setOption] = useState(getRandomOption);
  const [history, setHistory] = useState(() => []);
  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Do Now</Card.Title>
          <Card>
            <Card.Body>
              <Option option={option} />
              <div className="mt-2"></div>
              <Button
                variant="success"
                onClick={() => {
                  const historicalRecordItem = {};
                  Object.assign(historicalRecordItem, option);
                  historicalRecordItem.status = 'done';

                  setHistory((history) => [...history, historicalRecordItem]);

                  setOption(() => getRandomOption());
                }}
              >
                Done
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  const historicalRecordItem = {};
                  Object.assign(historicalRecordItem, option);
                  historicalRecordItem.status = 'skip';

                  setHistory((history) => [...history, historicalRecordItem]);

                  setOption(() => getRandomOption());
                }}
              >
                Skip
              </Button>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>History</Card.Title>
              <History options={history} />
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
      <DailySchedule />
    </>
  );
};

export default DoNow;
