import { useContext } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AppContext from '../../../AppContext';
import Card from '../../../shared/Card';
import { deletePrivatePost } from '../../../../api/privatePosts';
import PrivatePostsContext, {
  PrivatePostsProvider,
} from '../PrivatePostsContext';
import Post from './Post';
import PostForm, { PostFormProvider } from './PostForm';
import PostsAnalysis from './PostsAnalysis';
import PostsFeed from './PostsFeed';
import PostsInfo from './PostsInfo';
// TODO move FullScreen file up out of PostForm
import { FullScreenProvider } from './PostForm/FullScreen';

/**
 * TODO:
 * - loading posts should not delay write tab
 * - tags
 * - edit post
 *
 * IDEAS:
 * - title field
 * - elastic search
 * - search filters: modalities, date ranges, tags
 */

const PrivatePosts = () => {
  const { user } = useContext(AppContext);
  const { posts, postsLoading, reloadPosts } = useContext(PrivatePostsContext);

  return (
    <Card title="Private Posts">
      {/* TODO Don't do tabs.  It runs computations for all tabs.  Have separate routes. */}
      <Tabs defaultActiveKey="write">
        <Tab eventKey="write" title="Write">
          <div className="mt-4">
            <PostForm onSuccess={reloadPosts} />
          </div>
        </Tab>
        <Tab eventKey="posts" title="Posts">
          <PostsFeed
            loading={postsLoading}
            posts={posts}
            renderPost={(post) => (
              <Post
                post={post}
                key={post.id}
                deletePost={() => {
                  deletePrivatePost(
                    { uid: user.uid, postId: post.id },
                    reloadPosts
                  );
                }}
              />
            )}
          />
        </Tab>
        <Tab eventKey="stats" title="Stats">
          <PostsInfo loading={postsLoading} />
        </Tab>
        <Tab eventKey="analysis" title="Analysis">
          <PostsAnalysis posts={posts} />
        </Tab>
      </Tabs>
    </Card>
  );
};

const PrivatePostsProvided = () => (
  <PrivatePostsProvider>
    <FullScreenProvider>
      <PostFormProvider>
        <PrivatePosts />
      </PostFormProvider>
    </FullScreenProvider>
  </PrivatePostsProvider>
);

export default PrivatePostsProvided;
