import { useMemo } from 'react';
import Spinner from '../../../shared/Spinner';
import { CellClassNameEnum, CellType, PieceTypeValueEnum } from './types';

const FEN_HTML = Object.freeze({
  b: '&#9821;',
  k: '&#9818;',
  n: '&#9822;',
  p: '&#9823;',
  q: '&#9819;',
  r: '&#9820;',
  B: '&#9815;',
  K: '&#9812;',
  N: '&#9816;',
  P: '&#9817;',
  Q: '&#9813;',
  R: '&#9814;',
});

function getPieceView(piece: PieceTypeValueEnum, self: boolean) {
  if (self) {
    if (piece === 'knight') {
      return FEN_HTML['N'];
    }
    if (piece === 'king') {
      return FEN_HTML['K'];
    }
  }
  if (piece === 'knight') {
    return FEN_HTML['n'];
  }
  if (piece === 'king') {
    return FEN_HTML['k'];
  }
}

export function getCellRenderType(cell, isAdjacentToLand, isAdjacentToWater) {
  if (!cell || !cell.touched) {
    return isAdjacentToLand
      ? CellClassNameEnum.WATER
      : CellClassNameEnum.DEEP_WATER;
  }
  if (cell && cell.touched && cell.grass) {
    return CellClassNameEnum.GRASS;
  }
  if (cell && cell.touched && isAdjacentToWater) {
    return CellClassNameEnum.SAND;
  }
  return CellClassNameEnum.DIRT;
}

// TODO displayed Cell should be lightweight
export function Cell({
  cell,
  children,
  isAdjacentToLand,
  isAdjacentToWater,
  loading,
  myPiece,
  onClick,
  pieceColor,
  text,
}: {
  cell: CellType;
  children: React.ReactNode;
  isAdjacentToLand?: boolean;
  isAdjacentToWater?: boolean;
  text: React.ReactNode;
}) {
  const hasPieces = cell && cell.pieces && Object.keys(cell.pieces).length > 0;

  const className = useMemo(() => {
    return getCellRenderType(cell, isAdjacentToLand, isAdjacentToWater);
  }, [cell, isAdjacentToLand, isAdjacentToWater]);

  const zIndexedOverlayMessage = cell?.trees || null;
  const zIndexedOverlayMessageTreesClassName = 'trees';

  return (
    <td
      onClick={onClick}
      style={{
        height: 64,
        width: 64,
        minWidth: 64,
        position: 'relative',
      }}
      className={className}
    >
      {loading && (
        <div style={{ position: 'absolute', top: 16, left: 16, zIndex: 2 }}>
          <Spinner />
        </div>
      )}
      {myPiece && getPieceView(myPiece, true) && (
        <div
          className="piece"
          style={{ color: pieceColor }}
          dangerouslySetInnerHTML={{ __html: getPieceView(myPiece, true) }}
        />
      )}
      {!myPiece && hasPieces && (
        <div
          className="piece"
          style={{ color: 'black', zIndex: 0 }}
          // TODO use actual data to determine piece(s) in cell
          dangerouslySetInnerHTML={{ __html: getPieceView('knight') }}
        />
      )}
      {zIndexedOverlayMessage && (
        <div
          className={`piece ${zIndexedOverlayMessageTreesClassName}`}
          style={{
            zIndex: 2,
            marginLeft: 10,
            fontFamily: 'system-ui',
          }}
        >
          {zIndexedOverlayMessage}
        </div>
      )}
      <span
        className="small text-muted"
        style={{ position: 'absolute', top: 0, left: 0 }}
      >
        {text}
      </span>
      {children}
    </td>
  );
}
