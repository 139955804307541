import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Game from './Game';
import Games from './Games';
import Page from '../../Page';

const GamesRouter = () => {
  const location = useLocation();

  return (
    <Switch>
      <Route
        exact
        path="/games"
        key={location.pathname}
        render={(props) => (
          <Page title="Games | xBook">
            <Games {...props} />
          </Page>
        )}
      />
      <Route
        path="/games/:id"
        key={location.pathname}
        render={(props) => (
          <Page title="Game | xBook">
            <Game {...props} />
          </Page>
        )}
      />
    </Switch>
  );
};
export default GamesRouter;
