import { useState } from 'react';
import Card from '../../../shared/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';

function ListsList({ currentListId, lists, onClick }) {
  if (!lists) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '2rem' }}>
      {Object.values(lists)
        .sort((a, b) =>
          a.name?.localeCompare(b.name, undefined, { sensitivity: 'base' })
        )
        .map((list) => {
          const active = currentListId === list.id;
          return (
            <span
              key={list.id}
              onClick={() => onClick(list)}
              style={{
                width: 300,
                color: `${active ? 'white' : ''}`,
                backgroundColor: `${active ? '#0d6efd' : ''}`,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: 5,
              }}
            >
              {list.name}
            </span>
          );
        })}
    </div>
  );
}

const AddListForm = ({
  onSubmit,
}: {
  onSubmit: ({
    listName,
    onSuccess,
  }: {
    listName: string;
    onSuccess: () => void;
  }) => void;
}) => {
  const [newListName, setNewListName] = useState<string>('');
  const clearNewList = () => setNewListName('');
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({ listName: newListName, onSuccess: clearNewList });
      }}
    >
      <ButtonGroup style={{ width: '100%' }}>
        <Form.Control
          type="text"
          placeholder="list name"
          value={newListName}
          onChange={(e) => {
            setNewListName(e.target.value);
          }}
          autoFocus={true}
        />
        <Button style={{ whiteSpace: 'nowrap' }} type="submit">
          Create List
        </Button>
      </ButtonGroup>
    </Form>
  );
};

const Lists = ({
  createList,
  currentListId,
  lists,
  selectListId,
}: {
  createList: (name: string, onSuccess: () => void) => void;
  currentListId: string;
  lists: { [id: string]: { name: string; id: string } };
  selectListId: (listId: string) => void;
}) => (
  <Card title="Choose a list:">
    <div className="mb-3">
      <ListsList
        currentListId={currentListId}
        lists={lists}
        onClick={(list) => selectListId(list.id)}
      />
    </div>
    <AddListForm
      onSubmit={({ listName, onSuccess = () => null }) => {
        createList(listName, onSuccess);
      }}
    />
  </Card>
);

export default Lists;
