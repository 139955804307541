import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from '../../../../../shared/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { DEFAULT_HABITS, DEFAULT_NEW_HABIT, HABIT_TYPES, STATUS } from './data';

const getHabits = () => DEFAULT_HABITS;

const HabitsTableRowContents = ({
  deleteHabit,
  habit,
  habits,
  upsertHabit,
  habitKey,
}) => (
  <>
    <td>
      {habit.status === STATUS.DONE ? (
        <Button
          variant="success"
          size="lg"
          onClick={() => {
            delete habit.status;
            upsertHabit(habit);
          }}
        >
          Undo
        </Button>
      ) : (
        <Button
          variant="primary"
          size="lg"
          onClick={() => {
            habit.status = STATUS.DONE;
            upsertHabit(habit);
          }}
        >
          Done
        </Button>
      )}
    </td>
    <td>
      <Button
        variant="success"
        size="lg"
        onClick={() => {
          habit.count++;
          upsertHabit(habit);
        }}
      >
        +
      </Button>
      <Button
        variant="danger"
        size="lg"
        onClick={() => {
          habit.count >= 1 && habit.count--;
          upsertHabit(habit);
        }}
      >
        -
      </Button>
    </td>
    <td>{habit.count}</td>
    <td>{habit && habit.title}</td>
    <td>
      <span className="float-end">
        <Button
          variant="danger"
          size="sm"
          onClick={() => deleteHabit({ habitKey })}
        >
          Delete
        </Button>
      </span>
    </td>
  </>
);

const HabitsTable = (props) => {
  const [newHabit, setNewHabit] = useState(DEFAULT_NEW_HABIT);
  const { deleteHabit, habits, habitType, upsertHabit } = props;

  useEffect(() => {
    setNewHabit({ ...newHabit, type: habitType });
  }, []);

  const resetNewHabit = () => {
    setNewHabit({ ...DEFAULT_NEW_HABIT, type: habitType });
  };

  const addNewHabit = () => {
    if (newHabit.title) {
      upsertHabit(newHabit);
      resetNewHabit();
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Card
        title={props.title || 'Habits'}
        bg={props.variant}
        text={['light', null].includes(props.variant) ? 'dark' : 'white'}
      >
        <Table hover variant={props.variant}>
          <tbody>
            {Object.entries(habits)
              .filter(([key, habit]) => habit.status !== STATUS.DONE)
              .map(([habitKey, habit]) => (
                // each of these tr can actually be upgraded to fully responsive group-organized micro-features into micro-feature-sets eg the count and buttons wrapped
                <tr key={habitKey}>
                  <HabitsTableRowContents
                    habit={habit}
                    habits={habits}
                    upsertHabit={upsertHabit}
                    deleteHabit={deleteHabit}
                    habitKey={habitKey}
                  />
                </tr>
              ))}
            <tr>
              <td></td>
              <td>
                <Button
                  size="lg"
                  onClick={addNewHabit}
                  disabled={!newHabit.title}
                >
                  +
                </Button>
              </td>
              <td></td>
              <td>
                <Form.Control
                  type="text"
                  placeholder="my new habit"
                  value={newHabit.title}
                  onChange={(e) => {
                    setNewHabit({ ...newHabit, title: e.target.value });
                  }}
                  autoFocus={true}
                />
              </td>
              <td>
                <span className="float-end">
                  <Button
                    size="sm"
                    onClick={addNewHabit}
                    disabled={!newHabit.title}
                  >
                    Add
                  </Button>
                </span>
              </td>
            </tr>
            {Object.entries(habits)
              .filter(([key, habit]) => habit.status === STATUS.DONE)
              .map(([habitKey, habit]) => (
                // each of these tr can actually be upgraded to fully responsive group-organized micro-features into micro-feature-sets eg the count and buttons wrapped
                <tr key={habitKey} style={{ backgroundColor: 'lightgrey' }}>
                  <HabitsTableRowContents
                    habit={habit}
                    habits={habits}
                    upsertHabit={upsertHabit}
                    deleteHabit={deleteHabit}
                    habitKey={habitKey}
                  />
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
    </Form>
  );
};

const filterHabitsByType = (habits, type = '') =>
  Object.keys(habits).reduce((accumulator, key) => {
    if (habits[key].type === type) accumulator[key] = habits[key];
    return accumulator;
  }, {});

const filterHabitsByExcludeTypes = (habits, types = []) =>
  Object.keys(habits).reduce((accumulator, key) => {
    if (!types.includes(habits[key].type)) accumulator[key] = habits[key];
    return accumulator;
  }, {});

const Habits = () => {
  const [habits, setHabits] = useState({});

  useEffect(() => {
    // @TODO optimize to one line
    const _habits = getHabits();
    Object.entries(_habits).forEach(([key, habit]) => {
      habit.key = key;
      _habits[key] = habit;
    });
    setHabits(_habits);
  }, []);

  const deleteHabit = ({ habitKey }) => {
    const newHabits = { ...habits };
    delete newHabits[habitKey];
    setHabits(newHabits);
  };

  const upsertHabit = (habit) => {
    habit.key = habit.key || habit.title;
    setHabits({ ...habits, [habit.key]: habit });
  };

  // @TODO one table to rule them all
  return (
    <>
      <HabitsTable
        variant="success"
        title="Good Habits"
        habits={filterHabitsByType(habits, HABIT_TYPES.GOOD)}
        upsertHabit={upsertHabit}
        deleteHabit={deleteHabit}
        habitType={HABIT_TYPES.GOOD} // @TODO shouldn't need this
      />
      <HabitsTable
        variant={null}
        title="Basics"
        habits={filterHabitsByType(habits, HABIT_TYPES.BASIC)}
        upsertHabit={upsertHabit}
        deleteHabit={deleteHabit}
        habitType={HABIT_TYPES.BASIC} // @TODO shouldn't need this
      />
      <HabitsTable
        variant="danger"
        title="Bad Habits"
        habits={filterHabitsByType(habits, HABIT_TYPES.BAD)}
        upsertHabit={upsertHabit}
        deleteHabit={deleteHabit}
        habitType={HABIT_TYPES.BAD} // @TODO shouldn't need this
      />
      <HabitsTable
        variant={null}
        title="Overflow: Missing Type or Type Not Displayed By HabitsTable's Above"
        habits={filterHabitsByExcludeTypes(habits, [
          HABIT_TYPES.BAD,
          HABIT_TYPES.BASIC,
          HABIT_TYPES.GOOD,
        ])}
        upsertHabit={upsertHabit}
        deleteHabit={deleteHabit}
        habitType={null}
      />
    </>
  );
};
export default Habits;
