import React, { useContext, useEffect } from 'react';
import AppContext from './AppContext';
import LoginRequired from './shared/LoginRequired';
import Spinner from './shared/Spinner';

const Page = ({
  children,
  loginRequired = true,
  title,
}: {
  children: React.ReactNode;
  loginRequired: boolean;
  title: string;
}) => {
  const { loadingUser, user } = useContext(AppContext);

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  if (loginRequired && !user) {
    return <LoginRequired />;
  }

  if (loadingUser) {
    return <Spinner />;
  }

  return children;
};
export default Page;
