import React, { useContext, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import { removeTag } from '../../api/posts';
import AppContext from '../AppContext';

import './tag.css';

const TagBadge = (props) => (
  <Badge pill {...props} className={'bg-' + props.variant || 'bg-secondary'}>
    {props.children}
  </Badge>
);

// TODO we overuse this in the codebase
const Tag = ({ allowDelete = false, children, variant, postId }) => {
  const [showDelete, setShowDelete] = useState(false);
  const { user } = useContext(AppContext);

  if (allowDelete && postId && children && user.uid) {
    return (
      <div className="badge-group" onMouseLeave={() => setShowDelete(false)}>
        <TagBadge variant={variant} onMouseEnter={() => setShowDelete(true)}>
          {children}
        </TagBadge>
        {showDelete && (
          <TagBadge
            variant={'danger'}
            onClick={() => {
              removeTag({ postId, content: children, uid: user.uid });
            }}
          >
            x
          </TagBadge>
        )}
      </div>
    );
  }

  return <TagBadge variant={variant}>{children}</TagBadge>;
};

export default Tag;
