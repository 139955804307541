import firebase from '../components/firebase.js';

const upvoteUserRef = ({ postId, uid }) =>
  firebase.database().ref('posts/' + postId + '/upvote/' + uid);
const upvoteRef = ({ postId }) =>
  firebase.database().ref('posts/' + postId + '/upvote');

export const toggleUpvote = ({ postId, uid }) => {
  upvoteUserRef({ postId, uid })
    .once('value')
    .then((snapshot) => {
      const upvoteExists = !!snapshot.val();
      if (upvoteExists) {
        upvoteUserRef({ postId, uid }).remove();
      } else {
        upvoteUserRef({ postId, uid }).set(
          firebase.database.ServerValue.TIMESTAMP
        );
      }
    });
};

export const hasMyUpvote = ({ postId, uid }, callback) => {
  upvoteUserRef({ postId, uid }).on('value', (snapshot) => {
    const upvoteExists = !!snapshot.val();
    callback(upvoteExists);
  });
};

export const upvoteCount = ({ postId }, callback) => {
  upvoteRef({ postId }).on('value', (snapshot) => {
    const upvotes = snapshot.val();
    callback(upvotes ? Object.keys(upvotes).length : 0);
  });
};
