import { Link } from 'react-router-dom';
import Card from '../shared/Card';
import { Alpha, Beta, Favorite } from '../shared/icons';

// @TODO improve / clean this up:
// this is currently accessible by anyone (see Routes.js)
// but only visible in the menu to admin (see AppNav.js)

const Sitemap = () => {
  return (
    <>
      <Card
        className={'mt-2 me-2'}
        style={{ display: 'inline-block', verticalAlign: 'top' }}
      >
        <ul>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/admin">Admin</Link>
          </li>
          <li>
            <Link to="/donate">Donate</Link>
          </li>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/invitecodes">Invite Codes</Link>
          </li>
          <li>
            <Link to="/premium">Premium</Link>
          </li>
          <li>
            <Link to="/settings">Settings</Link>
          </li>
          <li>
            <Link to="/sitemap">Sitemap</Link>
          </li>
        </ul>
      </Card>
      <Card
        title="Public"
        className={'mt-2 me-2'}
        style={{ display: 'inline-block', verticalAlign: 'top' }}
      >
        <li>
          <Link to="/community">Community</Link>
        </li>
        <li>
          <Link to="/events">Events</Link>
          <Beta />
        </li>
        <li>
          <Link to="/public">Public Home</Link>
        </li>
        <li>
          <Link to="/queues">Queues</Link>
        </li>
        <li>
          <Link to="/rooms">Rooms</Link>
        </li>
        <li>
          <Link to="/training">Training</Link>
          <Favorite />
        </li>
        <li>
          <Link to="/write">Write</Link>
          <Beta />
        </li>
      </Card>
      <Card
        title="Private"
        className={'mt-2 me-2'}
        style={{ display: 'inline-block', verticalAlign: 'top' }}
      >
        <ul>
          <li>
            <Link to="/private">Private</Link>
            <Favorite />
          </li>
        </ul>
      </Card>
      <Card
        title="Apps"
        className={'mt-2 me-2'}
        style={{ display: 'inline-block', verticalAlign: 'top' }}
      >
        <ul>
          <li>
            <Link to="/blog">Blog</Link>
            <Beta />
          </li>
          <li>
            <Link to="/dashboard">Dashboard</Link>
            <Alpha />
          </li>
          <li>
            <Link to="/donow">Do Now</Link>
            <Alpha />
          </li>
          <li>
            <Link to="/lifeoptimizer">Life Optimizer</Link>
            <Beta />
          </li>
          <li>
            <Link to="/lists">Lists</Link>
            <Favorite />
          </li>
          <li>
            <Link to="/names">Names</Link>
            <Beta />
          </li>
          <li>
            <Link to="/scorekeeper">Score Keeper</Link>
          </li>
        </ul>
      </Card>
      <Card
        title="Games"
        className={'mt-2 me-2'}
        style={{ display: 'inline-block', verticalAlign: 'top' }}
      >
        <ul>
          <li>
            <Link to="/chess">Chess</Link>
            <Alpha />
          </li>
          <li>
            <Link to="/cultivation">Cultivation</Link>
            <Beta />
          </li>
          <li>
            <Link to="/fight-sim">Fight Sim</Link>
            <Alpha />
          </li>
          <li>
            <Link to="/games">Games Lobby</Link>
            <Alpha />
            <Favorite />
          </li>
          <li>
            <Link to="/incremental-clicker-game">Incremental Clicker</Link>
            <Beta />
          </li>
          <li>
            <Link to="/jackbox">Jackbox</Link>
            <Alpha />
          </li>
          <li>
            <Link to="/tictactoe">Tic-Tac-Toe</Link>
            <Alpha />
          </li>
          <li>
            <Link to="/time-balls">Time Balls</Link>
            <Alpha />
          </li>
          <li>
            <Link to="/simcity">Sim City</Link>
            <Beta />
          </li>
        </ul>
      </Card>
    </>
  );
};
export default Sitemap;
