import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleUp,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Card from '../../shared/Card';
import MODALITIES from '../../shared/Modalities/MODALITIES';

/**
 * TODO
 * - fix large popover readability
 * - should be a way to get from ModalityButton to modality details page
 * - better handle unrecognized modalityId
 * - make selected indication better for mobile, using active may be insufficient
 */

const ModalityInfoIcon = ({
  title,
  description,
}: {
  title: React.ReactNode;
  description: React.ReactNode;
}) =>
  description ? (
    <span className="ms-2">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Popover>
            <Popover.Header as="h3">{title}</Popover.Header>
            <Popover.Body>{description}</Popover.Body>
          </Popover>
        }
      >
        <span>
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </OverlayTrigger>
    </span>
  ) : null;

const ModalityButton = ({
  children,
  disabled,
  uniqueIdSuffixForToggle, // TODO ridiculous, should work without this, don't use ToggleButton
  modalityId,
  onClick,
  unselected,
  ...props
}: {
  children: React.ReactNode;
  disabled: boolean;
  uniqueIdSuffixForToggle: string;
  modalityId: string;
  onClick: () => any;
  unselected: boolean;
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const modality = MODALITIES[modalityId];

  if (!modality) {
    return null;
  }

  const { title, description } = modality;

  if (!title) {
    return null;
  }

  return (
    <>
      <ButtonGroup key={modalityId} className={'me-1 mb-1'} {...props}>
        <Button
          disabled={disabled}
          onClick={onClick}
          variant={unselected ? 'outline-secondary' : 'outline-dark'}
        >
          {children}
          {title}
          {/* <ModalityInfoIcon description={description} title={title} /> */}
        </Button>
        {description && (
          <ToggleButton
            id={
              'toggle-showDescription-' +
              modalityId +
              '-' +
              uniqueIdSuffixForToggle
            }
            type="checkbox"
            variant={unselected ? 'outline-secondary' : 'outline-dark'}
            checked={showDescription}
            value="showDescription"
            onChange={(e) => setShowDescription(e.currentTarget.checked)}
          >
            {showDescription ? (
              <FontAwesomeIcon icon={faChevronCircleUp} />
            ) : (
              <FontAwesomeIcon icon={faInfoCircle} />
            )}
          </ToggleButton>
        )}
      </ButtonGroup>
      {showDescription && (
        <Card key={modalityId + '-description'} title={title}>
          {description}
        </Card>
      )}
    </>
  );
};
export default ModalityButton;
