import firebase from '../components/firebase.js';
import {
  addNotificationsDeprecated,
  addNotificationsForMentionsIfDoesntAlreadyExist,
} from './notifications';
import { toggleUpvote } from './upvotes';

export const postRef = (postId) => firebase.database().ref('posts/' + postId);
export const postsRef = () => firebase.database().ref('posts');

export const createPost = ({
  content,
  replyToId = null,
  successCallback,
  uid,
  room,
  modality = null, //TODO handle modalities
}) => {
  if (!room) {
    console.error('createPost must receive value for room');
    return;
  }
  const post = {
    content,
    timestamp: firebase.database.ServerValue.TIMESTAMP,
    userId: uid,
    replyToId,
    room,
  };
  if (modality) {
    post.modalities = { [modality]: { name: modality } };
  }
  //hacky fix to prevent replies from having a modality
  if (replyToId) {
    delete post.modalities;
  }
  const postId = postsRef().push().key;
  postsRef()
    .child(postId)
    .update(post)
    .then(toggleUpvote({ postId, uid }))
    .then(replyToId && addNotificationsDeprecated({ postId: replyToId, uid }))
    .then(() => {
      addNotificationsForMentionsIfDoesntAlreadyExist({
        postContent: content,
        postId,
        myUserId: uid,
      });
    })
    .then(
      modality &&
        setModalityVote({
          postId,
          vote: true,
          uid,
          modalityName: modality,
        })
    )
    .then(
      successCallback &&
        typeof successCallback === 'function' &&
        successCallback()
    );
};

export const deletePost = ({ postId }) => {
  const postRef = postsRef().child(postId);
  postsRef()
    .orderByChild('replyToId')
    .equalTo(postId)
    .once('value', (snapshot) => {
      const replies = snapshot.val();
      if (!replies) {
        postRef.remove();
      } else {
        postRef.update({ deleted: true });
      }
      // decrementNotifications({ postId: replyToId, myUserId }); // TODO
    });
};

export const editPost = ({
  id,
  content,
  uid,
  successCallback,
  modality = null, //TODO handle modality edits??
}) => {
  postRef(id)
    .update({
      content,
    })
    .then(() => {
      addNotificationsForMentionsIfDoesntAlreadyExist({
        postContent: content,
        postId: id,
        myUserId: uid,
      });
    })
    .then(() => {
      successCallback();
    });
};

export const getPosts = (callback) => {
  postsRef().once('value', (snapshot) => {
    const posts = snapshot.val();
    callback(posts);
  });
};

const getModalityPostsArrayForUser = ({ uid }, callback) => {
  getPosts((posts) => {
    const modalityPosts = Object.values(posts).filter(
      (post) => post.userId === uid && post.modalities
    );
    callback(modalityPosts);
  });
};

export const getMostRecentModalityPostTimestampForUser = (
  { uid },
  callback
) => {
  getModalityPostsArrayForUser({ uid }, (modalityPostsArray) => {
    const mostRecentModalityPostTimestamp =
      modalityPostsArray.length > 0 &&
      Math.max.apply(
        Math,
        modalityPostsArray.map((post) => post.timestamp)
      );
    callback(mostRecentModalityPostTimestamp);
  });
};

export const addTag = ({ postId, content, successCallback, uid }) => {
  const key = postRef(postId).child('tags').push().key;
  postRef(postId)
    .child('tags')
    .child(key)
    .update({
      type: content,
      userId: uid,
    })
    .then(successCallback());
};

//TODO refactor someday
export const removeTag = ({ postId, content, uid }) => {
  postRef(postId)
    .child('tags')
    .once('value', (snapshot) => {
      const tags = snapshot.val();
      const tag =
        tags &&
        typeof tags === 'object' &&
        Object.entries(tags).filter(
          ([key, tag]) => tag.userId === uid && tag.type === content
        );
      if (tag && tag.length === 1) {
        const tagKey = tag[0][0];
        postRef(postId).child('tags').child(tagKey).remove();
      }
    });
};

export const setModalityVote = ({ postId, vote, uid, modalityName }) => {
  postsRef()
    .child(postId)
    .child('modality')
    .once('value', (snapshot) => {
      const modality = snapshot.val();
      if (modality && modality.name === modalityName) {
        postRef(postId).child('modality').child('votes').child(uid).set(vote);
      } else {
        console.error(
          'setModalityVote reached where modalityName does not exist as this post modality'
        );
      }
    });
  postsRef()
    .child(postId)
    .child('modalities')
    .once('value', (snapshot) => {
      const modalities = snapshot.val();
      if (modalities && modalities[modalityName]) {
        postRef(postId)
          .child('modalities')
          .child(modalityName)
          .child('votes')
          .child(uid)
          .set(vote);
      } else {
        console.error(
          'setModalityVote reached where modalityName does not exist on this post modalities'
        );
      }
    });
};
