import { LogInButton } from '../App/LogInButton';
import Card from './Card';

// TODO add ability to log in from here
const LoginRequired = () => {
  return (
    <Card title="Login Required">
      <p>Please log in to use this feature.</p>
      <LogInButton />
    </Card>
  );
};
export default LoginRequired;
