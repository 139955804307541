import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import useHandleKeyboardInput from './useHandleKeyboardInput';

function random(chance: number) {
  if (chance < 0 || chance > 1) {
    throw new Error('Chance must be between 0 and 1');
  }
  return Math.random() < chance;
}

function useBoardPlayerUnion({ position }) {
  const { boardPlayerUnion } = useMemo(() => {
    function makeCell() {
      return { water: random(0.3) ? true : false };
    }

    const board = Array.from({ length: 10 }, () =>
      Array.from({ length: 10 }, makeCell)
    );

    console.log('in useBoardPlayerUnion making a new boardPlayerUnion');
    const boardPlayerUnion = board.map((rowCells, i) =>
      rowCells.map((cell: Cell, j) => {
        if (i === position.x && j === position.y) {
          return { ...cell, player: true };
        }
        return cell;
      })
    );

    return { boardPlayerUnion };
  }, [position]);
  return { boardPlayerUnion };
}

const DEFAULT_POSITION = { x: 5, y: 5 };

type Position = {
  x: number;
  y: number;
};

interface GameContextType {
  position: Position;
  setPosition: (position: Position) => void;
}

const GameContext = createContext({
  position: DEFAULT_POSITION,
  setPosition: (position: Position) => {},
});

export const GameProvider = ({ children }: { children: React.ReactNode }) => {
  const [position, setPosition] = useState<Position>(DEFAULT_POSITION);

  const { boardPlayerUnion } = useBoardPlayerUnion({ position });
  useHandleKeyboardInput({ position, setPosition });

  return (
    <GameContext.Provider value={{ boardPlayerUnion, position, setPosition }}>
      {children}
    </GameContext.Provider>
  );
};

export const useGameContext = (): GameContextType => {
  const context = useContext(GameContext);
  if (context === undefined) {
    throw new Error('useGameContext must be used within a GameProvider');
  }
  return context;
};
