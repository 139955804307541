import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Page from '../../../Page';
import JackboxHome from './Home';
import JackboxRoom from './Room';

const JackboxRouter = () => {
  const location = useLocation();
  return (
    <Switch>
      <Route
        exact
        path="/jackbox"
        key={location.pathname}
        render={(props) => (
          <Page title="Jackbox | xBook">
            <JackboxHome {...props} />
          </Page>
        )}
      />
      <Route
        path="/jackbox/:type/:id"
        key={location.pathname}
        render={(props) => (
          <Page title="Jackbox | xBook">
            <JackboxRoom {...props} />
          </Page>
        )}
      />
    </Switch>
  );
};
export default JackboxRouter;
