import { useContext, useEffect, useState } from 'react';
import countWords from '../../shared/countWords';
import PrivatePostsContext from './PrivatePostsContext';
import { PostFormContext } from './Posts/PostForm';

const WordsNeeded = () => {
  const { analysis } = useContext(PrivatePostsContext);
  const { existingPostsWordCountToday, streaks } = analysis;
  const { content } = useContext(PostFormContext);

  const [wordsNeeded, setWordsNeeded] = useState<number>();

  useEffect(() => {
    const wordCount = countWords(content);
    const highestStreak =
      streaks && Math.max(...[0, ...Object.keys(streaks).map(Number)]);
    setWordsNeeded(highestStreak - (existingPostsWordCountToday + wordCount));
  }, [analysis, content]);

  if (!streaks || Object.keys(streaks).length === 0) {
    return <>You have no current streaks.</>;
  }

  return (
    <>
      You need <strong>{wordsNeeded}</strong> more words to not lose any of your
      streaks!
    </>
  );
};
export default WordsNeeded;
