import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const PageDoesNotExist = () => {
  const history = useHistory();
  return (
    <Card>
      <Card.Body>
        <Card.Title>404</Card.Title>
        <p>Page Does Not Exist</p>
        <Button
          variant="primary"
          onClick={() => {
            history.push(`/`);
          }}
        >
          Home Page
        </Button>
      </Card.Body>
    </Card>
  );
};
export default PageDoesNotExist;
