import React, { useContext } from 'react';
import Card from '../../../shared/Card';
import countWords from '../../../shared/countWords';
import FamousAuthorWordCounts from '../FamousAuthorWordCounts';
import { PostFormContext } from './PostForm';

const PostsInfoToday = ({
  existingPostsWordCountToday,
}: {
  existingPostsWordCountToday: number;
}) => {
  const { content } = useContext(PostFormContext);
  const unsavedWordCount = countWords(content);
  const totalWordsToday = existingPostsWordCountToday + unsavedWordCount;
  return (
    <Card title="Today">
      <ul>
        <li>
          {existingPostsWordCountToday} words + {unsavedWordCount} unsaved (
          <strong>{totalWordsToday}</strong> total)
        </li>
      </ul>
      <FamousAuthorWordCounts wordCount={totalWordsToday} />
    </Card>
  );
};
export default PostsInfoToday;
