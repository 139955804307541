import firebase from 'firebase';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyC301BeuAVDII84J7K0mq7AUJjUMgwRWYo',
  authDomain: 'react-firebase-2020-aecbd.firebaseapp.com',
  databaseURL: 'https://react-firebase-2020-aecbd-default-rtdb.firebaseio.com',
  projectId: 'react-firebase-2020-aecbd',
  storageBucket: 'react-firebase-2020-aecbd.appspot.com',
  messagingSenderId: '292817674787',
  appId: '1:292817674787:web:4f819f334fa5d10768c796',
  measurementId: 'G-N3MYLNTTHD',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const provider = new firebase.auth.GoogleAuthProvider();
// https://stackoverflow.com/a/43727675/1438029
provider.setCustomParameters({
  prompt: 'select_account',
});
export const auth = firebase.auth();
export default firebase;

export const db = firebase.firestore();
