import { faTrashAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '../../../AppContext';
import Card from '../../../shared/Card';
import Timestamp from '../../Private/Timestamp';
import { createGame, deleteGame, IGame, IGameStatus, onGames } from './api';

/**
 * TODO
 * - who's logged in
 * - live chat
 */

const GamesLobbyCard = ({ children, title }) => {
  return (
    <Card
      style={{
        maxWidth: '20rem',
        margin: '0 auto',
        display: 'block',
      }}
      title={title}
    >
      {children}
    </Card>
  );
};

const CLUE_SWEEPER_GAME_TYPE = 'codenames';

const CreateCodeNamesGame = () => {
  const type = CLUE_SWEEPER_GAME_TYPE;
  return (
    <GamesLobbyCard
      title={type === CLUE_SWEEPER_GAME_TYPE ? 'Clue Sweeper' : type}
    >
      <div>
        <Button
          onClick={() =>
            createGame({ type }, () => toast('Successfully created new game!'))
          }
          style={{ fontSize: '200%', width: '100%' }}
        >
          Create
        </Button>
      </div>
    </GamesLobbyCard>
  );
};

const DeleteGameButton = ({ gameId }: { gameId: string }) => {
  return (
    <Button
      onClick={() => {
        window.confirm('Confirm delete?') && deleteGame(gameId);
      }}
      variant="danger"
      size="sm"
      className="ms-3 mt-3"
    >
      <FontAwesomeIcon icon={faTrashAlt} />
    </Button>
  );
};

/**
 * @const GAME_STATUS_MAP
 * @description maps IGameStatus to GameActions label and button style variant
 * @type {{[IGameStatus]:{label: string, variant: string}}}
 */
const GAME_STATUS_MAP = Object.freeze({
  playing: {
    label: 'watch',
    variant: 'warning', // yellow
  },
  done: {
    label: 'view',
    variant: 'secondary', // grey
  },
  setup: {
    label: 'join',
    variant: 'success', // green
  },
  default: {
    label: 'undefined',
    variant: 'danger', // red
  },
});

const GameActions = ({
  gameId,
  gameStatus,
}: {
  gameId: string;
  gameStatus: IGameStatus;
}) => {
  const { user } = useContext(AppContext);
  const history = useHistory();

  const { label, variant } = (() =>
    GAME_STATUS_MAP[gameStatus] || GAME_STATUS_MAP['default'])();

  return (
    <>
      <Button
        onClick={() => history.push(`/games/${gameId}`)}
        style={{ fontSize: '200%', width: '100%' }}
        variant={variant}
      >
        {label}
      </Button>
      {user?.admin && <DeleteGameButton gameId={gameId} />}
    </>
  );
};

const GamesTable = ({ statusFilter }: { statusFilter: IGameStatus }) => {
  const { user } = useContext(AppContext);
  const [games, setGames] = useState<IGame[]>();

  useEffect(() => {
    onGames(setGames);
  }, [user]);

  const filteredGames = useMemo(
    () =>
      (games &&
        Object.entries(games)
          .filter(([id, game]) => {
            return !statusFilter || game.status === statusFilter;
          })
          .sort((a, b) => b[1].createdAt - a[1].createdAt)) ||
      [],
    [games, statusFilter]
  );

  return (
    <>
      {statusFilter === 'setup' && <CreateCodeNamesGame />}
      {filteredGames.map(([id, game]) => (
        <GamesLobbyCard
          key={id}
          title={
            game.type === CLUE_SWEEPER_GAME_TYPE ? 'Clue Sweeper' : game.type
          }
        >
          <div className="small text-muted">
            {game.createdAt && <Timestamp date={game.createdAt} />}
          </div>
          <div>
            {game.users ? Object.keys(game.users).length : 0}{' '}
            <FontAwesomeIcon icon={faUsers} />
          </div>
          <div>
            <GameActions gameId={id} gameStatus={game.status} />
          </div>
        </GamesLobbyCard>
      ))}
    </>
  );
};

const GamesLobby = () => {
  return (
    <Tabs defaultActiveKey="setup" className="my-3">
      <Tab eventKey="setup" title="Open">
        <GamesTable statusFilter={'setup'} />
      </Tab>
      <Tab eventKey="playing" title="Active">
        <GamesTable statusFilter={'playing'} />
      </Tab>
      <Tab eventKey="done" title="Complete">
        <GamesTable statusFilter={'done'} />
      </Tab>
    </Tabs>
  );
};

export default GamesLobby;
