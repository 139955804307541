import firebase from '../../../firebase.js';

type GameId = string;
export type IGameStatus = 'setup' | 'playing' | 'done';
export type IGameType = 'codenames';
type _IGame = {
  createdAt: number;
  settings: Object;
  status: IGameStatus;
  type: IGameType;
  users: { [uid: string]: any };
};
export type IGame = _IGame & { id: GameId };

const gamesRef = () => firebase.database().ref('games/lobby/games');
const gameRef = (id: GameId) =>
  firebase.database().ref('games/lobby/games/' + id);
const gameUserRef = (id: GameId, uid: string) =>
  firebase.database().ref('games/lobby/games/' + id + '/users/' + uid);

export const createGame = (
  { type }: { type: IGameType },
  callback = () => null
) => {
  const id = gamesRef().push().key as GameId;
  const game = {
    status: 'setup',
    type,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
  };
  gamesRef().child(id).set(game).then(callback());
};

export const onGames = (callback = (games: any) => null) => {
  gamesRef().on('value', (snapshot) => {
    callback(snapshot.val());
  });
};

export const onGame = (id: GameId, callback = (game: any) => null) => {
  gameRef(id).on('value', (snapshot) => {
    callback(snapshot.val());
  });
};

// TODO this _IGame stuff should actually be top level
// and the game key should be reserved for game type specific stuff
export const updateGame = (id: GameId, game: _IGame, callback = () => null) => {
  gameRef(id).update(game).then(callback());
};

export const deleteGame = (id: GameId) => {
  if (id) {
    gameRef(id).remove();
  }
};

export const updateGameUser = (
  id: string,
  uid: string,
  // TODO typescriptify this
  user,
  callback = () => null
) => {
  gameUserRef(id, uid).update(user).then(callback());
};
