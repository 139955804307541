import { GamePhase, IBoard, IRole, TeamColor } from './types';
import wordsTxtFile from './words.txt';
import emojisTxtFile from './xkcd-emojis.txt';

export const getEmojis = (callback: (lines: string[]) => void) =>
  fetch(emojisTxtFile)
    .then((response) => response.text())
    .then((data) => {
      const emojis: string[] = [];
      data.split('\n').forEach((emojiAndUnwantedText) => {
        // https://stackoverflow.com/a/42596897/1438029
        const chars = Array.from(emojiAndUnwantedText);
        emojis.push(chars[0]);
      });
      callback(emojis);
    });

// https://stackoverflow.com/a/7159251/1438029
export function getRandomArrayElements(arr: any[], count: number) {
  var shuffled = arr.slice(0),
    i = arr.length,
    min = i - count,
    temp,
    index;
  while (i-- > min) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(min);
}

export const getWords = (callback: (words: string[]) => void) =>
  fetch(wordsTxtFile)
    .then((response) => response.text())
    .then((data) => {
      callback(data.split('\n'));
    });

export const isGameOver = (board: IBoard) => {
  const cards = board.flat();
  const guessedAssassin = cards.some(
    (card) => card.owner === 'assassin' && card.revealed
  );
  const blueGuessedAll = cards
    .filter((card) => card.owner === 'blue')
    .every((card) => card.revealed);
  const redGuessedAll = cards
    .filter((card) => card.owner === 'red')
    .every((card) => card.revealed);
  const yellowGuessedAll = cards
    .filter((card) => card.owner === 'yellow')
    .every((card) => card.revealed);
  return guessedAssassin || blueGuessedAll || redGuessedAll || yellowGuessedAll;
};

export function nextGamePhase(
  gamePhase: GamePhase,
  gameHasThreeTeams: boolean
): GamePhase {
  if (gamePhase === 'setup') {
    return 'first-team-clue';
  }
  if (gamePhase === 'first-team-clue') {
    return 'first-team-guessing';
  }
  if (gamePhase === 'first-team-guessing') {
    return 'second-team-clue';
  }
  if (gamePhase === 'second-team-clue') {
    return 'second-team-guessing';
  }
  if (gamePhase === 'second-team-guessing' && gameHasThreeTeams) {
    return 'third-team-clue';
  }
  if (gamePhase === 'third-team-clue') {
    return 'third-team-guessing';
  }
  return 'first-team-clue';
}

export const styleVariantFromGamePhase = (
  phase: GamePhase,
  winner?: TeamColor
) => {
  if (phase === 'setup') {
    return 'secondary';
  }
  return styleVariantFromTeamColor(teamColorFromGamePhase(phase, winner));
};

export const styleVariantFromTeamColor = (teamColor: TeamColor | undefined) =>
  teamColor === 'red'
    ? 'danger'
    : teamColor === 'blue'
    ? 'primary'
    : teamColor === 'yellow'
    ? 'warning'
    : undefined;

export const teamColorFromGamePhase = (
  phase: GamePhase,
  winner?: TeamColor
): TeamColor | undefined => {
  if (['first-team-clue', 'first-team-guessing'].includes(phase)) {
    return 'red';
  }
  if (['second-team-clue', 'second-team-guessing'].includes(phase)) {
    return 'blue';
  }
  if (['third-team-clue', 'third-team-guessing'].includes(phase)) {
    return 'yellow';
  }
  if (phase === 'game-over') {
    return winner;
  }
};

export const teamRoleFromGamePhase = (phase: GamePhase): IRole | undefined => {
  if (
    ['first-team-clue', 'second-team-clue', 'third-team-clue'].includes(phase)
  ) {
    return 'clue-giver';
  }
  if (
    [
      'first-team-guessing',
      'second-team-guessing',
      'third-team-guessing',
    ].includes(phase)
  ) {
    return 'guesser';
  }
};
