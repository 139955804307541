import Button from 'react-bootstrap/Button';
import { cellsRef } from './api';

export function ResetWorldButton() {
  function resetWorld() {
    cellsRef().set({
      0: {
        0: { pieces: {}, touched: true },
      },
    });
  }
  return (
    <Button onClick={resetWorld} variant={'dark'}>
      Reset
    </Button>
  );
}
