const PiecePieceEnum = {
  KNIGHT: 'knight',
  KING: 'king',
} as const;

export type PieceTypeValueEnum =
  (typeof PiecePieceEnum)[keyof typeof PiecePieceEnum];

type PieceType = {
  piece: PieceTypeValueEnum;
};

export type CellType = {
  pieces: { [key: string]: PieceType };
  touched: Boolean; // TODO should be land, sorry, but it's technically correct which keeps this code more re-usable like a game engine, i should extract the branded layers if I would ever want to re-use or divide responsibilities with this code-base, like section out what I don't enjoy and hire someone lol <3
  trees: number;
};

type CellsDimensionTwoType = CellType; // in this two-dimensional worldMap case
type CellsDimensionOneType = { [key: number]: CellsDimensionTwoType };

export type CellsType = { [key: number]: CellsDimensionOneType };

export const CellClassNameEnum = {
  APPLES: 'apples',
  DIRT: 'dirt',
  DEEP_WATER: 'deep-water',
  GRASS: 'grass',
  SAND: 'sand',
  TREES: 'trees',
  WATER: 'water',
} as const;

type CellClassNameValueEnum =
  (typeof CellClassNameEnum)[keyof typeof CellClassNameEnum];

export type RowColType = [number, number]; // TODO is this true or is it number | string for both?

export type ChessWorldUserType = {
  position: RowColType;
  moves: number;
  water: number;
  apples: number;
};
