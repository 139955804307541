import addMonths from 'date-fns/addMonths';
import toDate from 'date-fns/toDate';
import firebase, { db } from '../components/firebase.js';
import { isPremium } from '../components/shared/User';

export const getUser = (uid, callback) => {
  db.collection('users')
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const user = doc.data();
        user.uid = doc.id;
        firebase
          .database()
          .ref('users/' + user.uid)
          .once('value', (snapshot) => {
            const realtimeUser = snapshot.val();
            const mergedUser = Object.assign(user, realtimeUser);
            mergedUser.isPremium = isPremium({ premium: mergedUser.premium });
            callback(mergedUser);
          });
      } else {
        console.warn('User not found! uid: ', uid);
        callback();
      }
    });
};

export const getUsers = (callback) => {
  const users = {};
  db.collection('users')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        users[doc.id] = doc.data();
        users[doc.id].uid = doc.id;
        users[doc.id].isPremium = isPremium({ premium: users[doc.id].premium });
      });
      callback(users);
    });
};

export const getUserByUsername = ({ username }, callback) => {
  db.collection('users')
    .where('username', '==', username)
    .get()
    .then((querySnapshot) => {
      const uidMatchesForUsername = [];
      querySnapshot.forEach((doc) => {
        uidMatchesForUsername.push(doc.id);
      });
      if (uidMatchesForUsername.length === 1) {
        getUser(uidMatchesForUsername[0], callback);
      } else {
        callback(false);
      }
    });
};

export const getUsersRealtimeDatabase = (callback) => {
  const usersRef = firebase.database().ref('users');
  usersRef.once('value', (snapshot) => {
    const users = snapshot.val();
    callback(users);
  });
};

export const updateUser = ({ uid, user }, callback) => {
  db.collection('users')
    .doc(uid)
    .set(user, { merge: true })
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};

export const updateUsername = ({ uid, username }, callback) => {
  getUserByUsername({ username }, (userWithUsernameAlreadyExists) => {
    if (!userWithUsernameAlreadyExists) {
      db.collection('users')
        .doc(uid)
        .update({ username: username })
        .then(() => {
          typeof callback === 'function' && callback(true);
        });
    } else {
      callback(false);
    }
  });
};

export const addPremium = ({ uid, months }, callback) => {
  db.collection('users')
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const user = doc.data();
        const baseDate = isPremium({ premium: user.premium })
          ? toDate(user.premium.seconds * 1000)
          : new Date();
        const newPremiumExpiryDate = addMonths(baseDate, months);
        updateUser({ uid, user: { premium: newPremiumExpiryDate } }, callback);
      }
    });
};

export const followUser = ({ myUid, followUid }, callback) => {
  db.collection('users')
    .doc(myUid)
    .update({
      following: firebase.firestore.FieldValue.arrayUnion(followUid),
    })
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};
export const unfollowUser = ({ myUid, followUid }, callback) => {
  db.collection('users')
    .doc(myUid)
    .update({
      following: firebase.firestore.FieldValue.arrayRemove(followUid),
    })
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};
