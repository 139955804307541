import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/card';

const BlogPost = ({ post }) => (
  <Card>
    <Card.Body>
      <pre>{post.content}</pre>
    </Card.Body>
  </Card>
);

const BlogRoll = () => {
  const [posts, setPosts] = useState([]);

  // @TODO clean this up, make more scalable
  // @TODO handle post order
  useEffect(() => {
    const fetchData = async () => {
      const newPosts = [...posts];
      await fetch('/blog-posts/reality-goodness-work.txt')
        .then((r) => r.text())
        .then((text) => {
          newPosts.push({ content: text });
        });
      await fetch('/blog-posts/upsides-downsides.txt')
        .then((r) => r.text())
        .then((text) => {
          newPosts.push({ content: text });
        });
      await fetch('/blog-posts/sunday-morning.txt')
        .then((r) => r.text())
        .then((text) => {
          newPosts.push({ content: text });
        });
      await fetch('/blog-posts/2021-11.txt')
        .then((r) => r.text())
        .then((text) => {
          newPosts.push({ content: text });
        });
      setPosts(newPosts);
    };
    fetchData();
  }, []);

  return (
    <>
      {posts.map((post) => (
        <BlogPost post={post} />
      ))}
    </>
  );
};

const Blog = () => {
  return <BlogRoll />;
};

export default Blog;
