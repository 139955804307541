import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_VISIBLE = true;

const ShowHideToggle = ({
  children,
  label,
  localStorageKeyPrefix,
}: {
  children: React.ReactNode;
  label: string;
  localStorageKeyPrefix?: string;
}) => {
  const [visible, _setVisible] = useState(
    localStorageKeyPrefix
      ? window.localStorage.getItem(localStorageKeyPrefix + '.visible') ===
          'true'
      : DEFAULT_VISIBLE
  );
  const setVisible = (newVisible: boolean) => {
    _setVisible(newVisible);
    localStorageKeyPrefix &&
      window.localStorage.setItem(
        localStorageKeyPrefix + '.visible',
        newVisible.toString()
      );
  };
  return (
    <>
      <div className="mb-2" onClick={() => setVisible(!visible)}>
        <span className="h6">{label}</span>
        <span className="mx-2 text-muted small">
          <FontAwesomeIcon
            icon={visible ? faEye : faEyeSlash}
            className="fa-fw me-1"
          />
          {visible ? 'hide' : 'show'}
        </span>
      </div>
      {visible && children}
    </>
  );
};
export default ShowHideToggle;
