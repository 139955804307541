/**
 * TODO DO THIS GENERAL ORDER OF PREFERENCE:
 * EFFICIENCY MODE:
 * 1. items without any existing relationships, efficiency: match against highest then lowest etc
 * 2. items from same rank, starting from highest rank
 * 3. least compared items
 *
 * FUN MODE would focus on 1. compare from similar rank 2. move around the ranks each time
 */

import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import './CompareForm.css';

// TODO clean this up and write tests
const getTwoUncomparedItems = (items, relationships) => {
  const itemsKeys = Object.keys(items);
  if (itemsKeys.length < 2) return;

  const twoRandomItems = [];

  const theseTwoAlreadyCompared = () =>
    twoRandomItems.length >= 2 &&
    Object.values(relationships)
      .map((relationship) => relationship.options)
      .some((pair) =>
        twoRandomItems
          .map((item) => item.id)
          .every((item) => pair.includes(item))
      );

  while (twoRandomItems.length < 2 || theseTwoAlreadyCompared()) {
    twoRandomItems.splice(0, twoRandomItems.length);
    const firstItemsKeysKey = (itemsKeys.length * Math.random()) << 0;
    twoRandomItems.push(items[itemsKeys[firstItemsKeysKey]]);
    let secondItemsKeysKey = firstItemsKeysKey;
    while (secondItemsKeysKey === firstItemsKeysKey) {
      secondItemsKeysKey = (itemsKeys.length * Math.random()) << 0;
    }
    twoRandomItems.push(items[itemsKeys[secondItemsKeysKey]]);
  }

  return twoRandomItems;
};

/**
 * TODO this functionality need not be limited to only comparing 2 items.
 CompareItemsForm functionality+ could actually be merged with HiearchyDisplay functionality.
 addForCompare could add to a group of items that is displayed like HiearchyDisplay,
 and clicking on one of them would mark it as superior to all others in that collection.
 Could have arbitrarily deep sub-sections, branches, sub-groups of sub-groups.
 */
// TODO fix bug: do not allow comparison of item to self
export default function CompareItemsForm({
  items,
  itemsToCompare,
  relationships,
  addRelationship,
}) {
  const [twoItems, setTwoItems] = useState([]);

  const getTwoNewItems = () =>
    setTwoItems(getTwoUncomparedItems(items, relationships));

  useEffect(() => {
    if (itemsToCompare && itemsToCompare.length === 2) {
      setTwoItems(itemsToCompare);
    } else if (itemsToCompare && itemsToCompare.length === 1) {
      if (twoItems && twoItems.length > 0) {
        setTwoItems([itemsToCompare[0], twoItems[0]]);
      } else {
        getTwoNewItems();
        setTwoItems([itemsToCompare[0], twoItems[0]]);
      }
    } else {
      getTwoNewItems();
    }
  }, [items, itemsToCompare]);

  if (!twoItems || twoItems.length < 2) {
    return <pre className="text-info">Requires 2 or more items</pre>;
  }

  return (
    <>
      <Button
        className="mt-1 compare-form-button"
        onClick={() => {
          const relationship = {
            options: twoItems.map((item) => item.id),
            selection: twoItems[0].id,
          };
          addRelationship(relationship, getTwoNewItems);
        }}
      >
        {twoItems[0].id}
      </Button>
      <Button
        className="mt-1 compare-form-button"
        onClick={() => {
          // TODO dry w state and TODO relationship model
          const relationship = {
            options: twoItems.map((item) => item.id),
            selection: twoItems[1].id,
          };
          addRelationship(relationship, getTwoNewItems);
        }}
      >
        {twoItems[1].id}
      </Button>
      <Button
        className="mt-1 compare-form-button"
        variant="light"
        onClick={() => {
          getTwoNewItems();
        }}
      >
        <span className="text-muted">&#8811; skip &#8811;</span>
      </Button>
    </>
  );
}
