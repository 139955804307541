import format from 'date-fns/format';

// TODO include option for displaying elapsed time like FriendlyTimestamp does
// TODO tests for hideHours

export const readableDate = (date: Date, options = { hideHours: false }) =>
  options.hideHours
    ? format(date, 'MMMM d, yyyy')
    : format(date, "MMMM d, yyyy 'at' h:mm b");

const Timestamp = ({ date, hideHours }: { date: Date; hideHours: boolean }) => {
  return <span>{readableDate(date, { hideHours })}</span>;
};
export default Timestamp;
