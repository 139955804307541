import React from 'react';

function UlNoStyle({ children, ...props }) {
  return (
    <ul style={{ listStyle: 'none', padding: 0, margin: 0 }} {...props}>
      {children}
    </ul>
  );
}

const Ul = {
  NoStyle: UlNoStyle,
};

export { Ul };
