export function getAdjacency({ getCell, row, col }) {
  const laterallyAdjacentCells = [
    getCell(row + 1, col),
    getCell(row - 1, col),
    getCell(row, col + 1),
    getCell(row, col - 1),
  ];
  const adjacentCells = [
    ...laterallyAdjacentCells,
    getCell(row + 1, col + 1),
    getCell(row + 1, col - 1),
    getCell(row - 1, col + 1),
    getCell(row - 1, col - 1),
  ];
  const isLand = (cell) => cell?.touched;
  const isAdjacentToLand = adjacentCells.some((cell) => isLand(cell));
  const isWater = (cell) => !cell || !cell.touched;
  const isAdjacentToWater = laterallyAdjacentCells.some((cell) =>
    isWater(cell)
  );
  return [isAdjacentToLand, isAdjacentToWater];
}

export const consecutiveIntegerArray = (
  fromLowInteger: number,
  toHighInteger: number
): number[] =>
  // @ts-ignore
  Array.apply(null, {
    length: toHighInteger + 1 - fromLowInteger,
  }).map(function (_, idx) {
    return idx + fromLowInteger;
  });

const Board = ({
  renderCell,
  center,
  radius,
  ...props
}: {
  renderCell: (row: number, col: number) => React.ReactNode;
  center: { row: number; col: number };
  radius: number;
}) => {
  const { row, col } = center;
  const rows = consecutiveIntegerArray(row - radius, row + radius).reverse();
  const cols = consecutiveIntegerArray(col - radius, col + radius);
  return (
    <table {...props}>
      <tbody>
        {rows.map((row) => (
          <tr key={row}>{cols.map((col) => renderCell(row, col))}</tr>
        ))}
      </tbody>
    </table>
  );
};
export default Board;
