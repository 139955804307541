import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const AppContainer = ({ children }) => (
  <Container fluid>
    <Row>
      <Col style={{ paddingRight: 0, paddingLeft: 0 }}>{children}</Col>
    </Row>
  </Container>
);
export default AppContainer;
