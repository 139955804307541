import firebase, { db } from '../components/firebase.js';
import { updateUser } from './users';

export const createInviteCode = ({ uid }, callback) => {
  const newInviteCodeRef = db.collection('inviteCodes').doc();
  newInviteCodeRef
    .set({ uid, createdAt: firebase.firestore.FieldValue.serverTimestamp() })
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};

export const setInviteCodeNotes = ({ id, notes }, callback) => {
  db.collection('inviteCodes')
    .doc(id)
    .set({ notes }, { merge: true })
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};

export const getInviteCodes = ({ uid }, callback) => {
  const inviteCodes = {};
  db.collection('inviteCodes')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const inviteCode = doc.data();
        if (inviteCode.uid === uid) {
          const uniqueKey = doc.id;
          inviteCodes[uniqueKey] = inviteCode;
          inviteCodes[uniqueKey].id = uniqueKey;
        }
      });
      callback(inviteCodes);
    });
};

export const processInviteCode = ({ uid, inviteCode }, callback) => {
  const inviteCodeRef = db.collection('inviteCodes').doc(inviteCode);
  //1. does valid inviteCode exist, get source uid
  inviteCodeRef.get().then((doc) => {
    if (doc.exists) {
      //2. update user w invitedBy source uid
      updateUser({ uid, user: { invitedBy: doc.data().uid } }, (data) => {
        //3. do not delete invite code
        // inviteCodeRef.delete();
        callback && typeof callback === 'function' && callback(data);
      });
    }
  });
};

export const deleteInviteCode = ({ inviteCodeId }, callback) => {
  db.collection('inviteCodes')
    .doc(inviteCodeId)
    .delete()
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};
