import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';
import Table from 'react-bootstrap/Table';
import { db } from '../../../firebase';
import AppContext from '../../../AppContext';

/**
 * @todo requirement: offline mode
 *
 * @todo simple table sortable
 * @todo quick/easy live searchable
 */

const getNames = ({ uid }, callback) => {
  const names = {};
  db.collection('namesUsersData')
    .doc(uid)
    .collection('names')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        names[doc.id] = doc.data();
        names[doc.id].id = doc.id;
      });
      callback(names);
    });
};
const upsertName = ({ uid, name }, callback) => {
  const nameRef = db
    .collection('namesUsersData')
    .doc(uid)
    .collection('names')
    .doc(name.id);
  db.collection('namesUsersData')
    .doc(uid)
    .collection('names')
    .doc(nameRef.id)
    .set(name)
    .then(() => {
      callback && typeof callback === 'function' && callback(nameRef.id);
    });
};
const apiDeleteName = ({ uid, id }, callback) => {
  db.collection('namesUsersData')
    .doc(uid)
    .collection('names')
    .doc(id)
    .delete()
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};

const NameForm = ({ addName }) => {
  const [autofocusRef, setAutofocusRef] = useState(React.createRef());
  const [date, setDate] = useState(''); //@todo use today's date
  const [name, setName] = useState('');
  const resetForm = () => {
    setDate('');
    setName('');
  };
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        addName({ date, name });
        resetForm();
        autofocusRef.current.focus();
      }}
    >
      <Card>
        <Card.Body>
          <Card.Title>Add Name</Card.Title>
          <div className="mt-2">
            <FormLabel>Date</FormLabel>
            <Form.Control
              key="date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div>
            <FormLabel>Name</FormLabel>
            <Form.Control
              key="name"
              ref={autofocusRef}
              autoFocus={true}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <Button type="submit">Add Name</Button>
          </div>
        </Card.Body>
      </Card>
    </Form>
  );
};

const sortHighValue = (a, b) => b.value - a.value;
const sortLowValue = (a, b) => a.value - b.value;
const sortExtremeValue = (a, b) => Math.abs(b.value) - Math.abs(a.value);

const NamesTable = ({ names, deleteName }) => {
  const [sortFunction, setSortFunction] = useState(() => sortHighValue);
  const sortedNamesArray = Object.values(names).sort(sortFunction);
  return (
    <>
      {/* <Card>
        <Card.Body>
          <Card.Title>Sort</Card.Title>
          <Button
            variant="link"
            onClick={() => setSortFunction(() => sortHighValue)}
          >
            Positive Value
          </Button>
          <Button
            variant="link"
            onClick={() => setSortFunction(() => sortLowValue)}
          >
            Negative Value
          </Button>
          <Button
            variant="link"
            onClick={() => setSortFunction(() => sortExtremeValue)}
          >
            Extreme Value
          </Button>
        </Card.Body>
      </Card> */}
      <Card>
        <Card.Body>
          <Card.Title>Names</Card.Title>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedNamesArray.map((name) => (
                <tr>
                  <td style={{ whiteSpace: 'nowrap' }}>{name.date}</td>
                  <td>{name.name}</td>
                  <td>
                    <Button variant="link" onClick={() => deleteName(name.id)}>
                      delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

const Names = () => {
  const {
    user: { uid },
  } = useContext(AppContext);
  const [names, setNames] = useState({});
  const addName = (name) => {
    upsertName({ uid, name }, (nameId) => {
      setNames({ ...names, [nameId]: { ...name, id: nameId } });
    });
  };
  const deleteName = (id) => {
    apiDeleteName({ uid, id }, () => {
      const {
        [id]: {},
        ...otherNames
      } = names;
      setNames(otherNames);
    });
  };
  useEffect(() => {
    getNames({ uid }, (names) => {
      setNames(names);
    });
  }, []);
  return (
    <div>
      <div className="mt-3">
        <Card>
          <Card.Body>
            <Card.Title>Names</Card.Title>
            <ul>
              <li>Record names of people you meet.</li>
            </ul>
          </Card.Body>
        </Card>
      </div>
      <div className="mt-3">
        <NameForm addName={addName} />
      </div>
      <div className="mt-3">
        <NamesTable names={names} deleteName={deleteName} />
      </div>
    </div>
  );
};
export default Names;
