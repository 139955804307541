import { availableModalities } from '../../../../shared/Modalities';
import ModalityButton from '../../ModalityButton';

// TODO more compact like a toggle-able popover writing library
// TODO mouseover icon info is sometimes so much it runs off screen and can't read it
const ModalitySelect = ({
  disabled,
  selectedModalityIds = [],
  toggleSelect,
}) => {
  return (
    <div>
      {Object.values(availableModalities)
        // TODO something wonky here w "id" and "key", get straight on this and throughout app
        // TODO AND THE DATABASE CALLS IT NAME!!  id & key & name should be carefully disentangled everywhere
        .filter((modality) => !selectedModalityIds.includes(modality.key))
        .map((modality) => (
          <ModalityButton
            key={modality.key}
            unselected={true}
            disabled={disabled}
            modalityId={modality.key}
            onClick={() => toggleSelect(modality.key)}
            size="sm"
          />
        ))}
    </div>
  );
};
export default ModalitySelect;
