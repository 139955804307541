import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AppContext from '../../AppContext';
import { getAccounting } from '../../../api/accounting';
import { getPosts } from '../../../api/posts';
import { getUsersRealtimeDatabase } from '../../../api/users';
import Card from '../../shared/Card';
import getMillisFromDifferingTypes from '../../shared/getMillisFromDifferingTypes';
import Spinner from '../../shared/Spinner';
import { isPremium } from '../../shared/User';
import Accounting, { AccountingMiniCard } from './Accounting';
import Funnel from './Funnel';
import { PostsMiniCard, PostsPerDay } from './Posts';
import { Users, UsersTable } from './Users';

const PremiumUsers = ({ usersArray }) => {
  return (
    <>
      <div>
        <strong>Uids of Users without Premium</strong>
      </div>
      <code>
        {usersArray
          .filter((user) => !user.isPremium)
          .map((user) => '@' + user.uid)
          .join(' ')}
      </code>
    </>
  );
};

export default class Admin extends React.Component {
  constructor() {
    super();
    this.state = {
      accounting: {},
      posts: {},
      users: {},
      sortKey: 'lastOnline',
    };
  }

  static contextType = AppContext;
  user = () => this.context.user;
  users = () => this.context.users;

  componentDidMount() {
    // lastOnline lives in realtime database
    getUsersRealtimeDatabase((users) => {
      this.setState({
        users,
      });
    });
    getPosts((posts) => {
      this.setState({ posts });
    });
    getAccounting((accounting) =>
      this.setState({
        accounting,
      })
    );
  }

  sort(propertyName) {
    this.setState({
      sortKey: propertyName,
    });
  }

  render() {
    if (!this.user() || !this.users()) {
      return <Spinner size="lg" />;
    }
    if (!this.user().admin) {
      return <>Access Denied</>;
    }

    const users = this.users();

    // merge lastOnline from state
    Object.entries(users).forEach(([key, user]) => {
      if (this.state.users && this.state.users[user.uid]) {
        users[key].lastOnline = this.state.users[user.uid].lastOnline;
      }
    });

    Object.values(users).forEach((user) => {
      if (users[user.uid]) {
        // TODO in db: convert joined to object type
        users[user.uid].joined =
          users[user.uid].joined &&
          getMillisFromDifferingTypes(users[user.uid].joined);
        // TODO in db: delete number/string lastLogins from db, only keep object versions; or convert
        users[user.uid].lastLogin =
          users[user.uid].lastLogin &&
          getMillisFromDifferingTypes(users[user.uid].lastLogin);
        users[user.uid].isPremium = isPremium({
          premium: users[user.uid].premium,
        });
      }
    });

    const usersArray = Object.values(users);

    const sortKey = this.state.sortKey;
    if (sortKey) {
      usersArray.sort((a, b) => {
        if (!a[sortKey]) {
          return 1;
        }
        if (!b[sortKey]) {
          return -1;
        }
        if (typeof a[sortKey] === 'string') {
          return a[sortKey].localeCompare(b[sortKey]);
        }
        if (typeof a[sortKey] === 'number') {
          return b[sortKey] - a[sortKey];
        }
      });
    }

    return (
      <Card className="mt-3" title="Admin">
        <Tabs defaultActiveKey="overview" className="my-3">
          <Tab eventKey="overview" title="Overview">
            <Card
              title="Users"
              style={{ display: 'inline-block', verticalAlign: 'top' }}
            >
              <Users usersArray={usersArray} posts={this.state.posts} />
            </Card>
            <Card
              title="Posts"
              className="ms-3"
              style={{ display: 'inline-block', verticalAlign: 'top' }}
            >
              <PostsMiniCard posts={this.state.posts} />
            </Card>
            <Card
              title="Accounting"
              className="ms-3"
              style={{ display: 'inline-block', verticalAlign: 'top' }}
            >
              <AccountingMiniCard
                accounting={this.state.accounting}
                users={this.state.users}
              />
            </Card>
          </Tab>
          <Tab eventKey="users" title="Users">
            <Card className={'mt-3'} title="Users">
              <Users usersArray={usersArray} posts={this.state.posts} />
            </Card>
            <Card className={'mt-3'}>
              <PremiumUsers usersArray={usersArray} />
            </Card>
            <Card className={'mt-3'}>
              <Funnel usersArray={usersArray} posts={this.state.posts} />
            </Card>
            <Card className={'mt-3'}>
              <UsersTable
                setSort={(propertyName) => this.sort(propertyName)}
                usersArray={usersArray}
              />
            </Card>
          </Tab>
          <Tab eventKey="posts" title="Posts">
            <Card className={'mt-3'} title="Posts">
              <PostsMiniCard posts={this.state.posts} />
            </Card>
            <div className={'mt-3'}>
              <PostsPerDay posts={this.state.posts} />
            </div>
          </Tab>
          <Tab eventKey="accounting" title="Accounting">
            <Card className={'mt-3'} title="Accounting">
              <AccountingMiniCard
                accounting={this.state.accounting}
                users={this.state.users}
              />
            </Card>
            <div className={'mt-3'}>
              <Accounting
                accounting={this.state.accounting}
                users={this.state.users}
              />
            </div>
          </Tab>
        </Tabs>
      </Card>
    );
  }
}
