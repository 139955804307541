const TargetCount = ({
  label,
  target,
  actual,
}: {
  label: string;
  target: number;
  actual: number;
}) => {
  const done = actual > target;
  return (
    <>
      <span style={{ textDecoration: done ? 'line-through' : 'inherit' }}>
        {`${label}: ${target} Words`}
      </span>{' '}
      {done && <>&#10003;</>}
      {!done && <span className="text-danger">{`${actual - target}`}</span>}
    </>
  );
};

// https://wordcounter.net/blog/2017/12/04/103207_the-daily-word-counts-of-19-famous-writers.html
const famousAuthorDailyWordCounts: {
  name: string;
  wordCount: number;
  wordCountHigh?: number;
}[] = [
  { name: 'Ernest Hemingway', wordCount: 500 },
  { name: 'Tom Wolfe', wordCount: 135 },
  { name: 'W. Somerset Maugham', wordCount: 1000 },
  { name: 'Jack London', wordCount: 1500 },
  { name: 'Stephen King', wordCount: 2000 },
  { name: 'Graham Greene', wordCount: 500 },
  { name: 'Ian McEwan', wordCount: 600 },
  { name: 'Arthur Hailey', wordCount: 600 },
  { name: 'Sarah Waters', wordCount: 1000 },
  { name: 'Mark Twain', wordCount: 1400, wordCountHigh: 1800 },
  { name: 'Kate DiCamillo', wordCount: 600, wordCountHigh: 900 },
  { name: 'Sebastian Faulks', wordCount: 1000 },
  { name: 'J.G. Ballard', wordCount: 1000 },
  { name: 'Lee Child', wordCount: 1800 },
  { name: 'Nicholas Sparks', wordCount: 2000 },
  { name: 'Holly Black', wordCount: 1000 },
  { name: 'Anne Rice', wordCount: 3000 },
  { name: 'Arthur Conan Doyle', wordCount: 3000 },
  { name: 'Michael Crichton', wordCount: 10000 },
];

function FamousAuthorWordCounts({ wordCount }: { wordCount: number }) {
  return (
    <>
      <p>
        <em>You've written more than:</em>
        <br />
        <small>Daily Word Counts of:</small>
      </p>
      <ul>
        {famousAuthorDailyWordCounts
          .sort((a, b) => {
            if (a.wordCount === b.wordCount) {
              return a.name.localeCompare(b.name);
            }
            return a.wordCount - b.wordCount;
          })
          .map((authorWordCount) => (
            <li key={authorWordCount.name}>
              <TargetCount
                label={authorWordCount.name}
                target={authorWordCount.wordCount}
                actual={wordCount}
              />
            </li>
          ))}
      </ul>
    </>
  );
}

export default FamousAuthorWordCounts;
