// type KnightMoveType
// type KingMoveType

import { RowColType } from './types';

// Knights are more like crocadiles now, amphibious compared to the "kings"
const rowColChangeByKnightMove = {
  UP2RIGHT1: [+2, +1],
  UP1RIGHT2: [+1, +2],
  RIGHT2DOWN1: [-1, +2],
  RIGHT1DOWN2: [-2, +1],
  DOWN2LEFT1: [-2, -1],
  DOWN1LEFT2: [-1, -2],
  UP2LEFT1: [+2, -1],
  UP1LEFT2: [+1, -2],
};

const rowColChangeByKingMove = {
  UP: [+1, 0],
  UP_RIGHT: [+1, +1],
  RIGHT: [0, +1],
  DOWN_RIGHT: [-1, +1],
  DOWN: [-1, 0],
  DOWN_LEFT: [-1, -1],
  LEFT: [0, -1],
  UP_LEFT: [+1, -1],
};

const KNIGHT_KEYS_MAP = {
  Digit3: 'UP2RIGHT1',
  KeyE: 'UP1RIGHT2',
  KeyD: 'RIGHT2DOWN1',
  KeyX: 'RIGHT1DOWN2',
  KeyZ: 'DOWN2LEFT1',
  KeyA: 'DOWN1LEFT2',
  KeyQ: 'UP1LEFT2',
  Digit2: 'UP2LEFT1',
};

const ROOKIFIED_KING_KEYS_MAP = {
  KeyW: 'UP',
  KeyA: 'LEFT',
  KeyS: 'DOWN',
  KeyD: 'RIGHT',
};

type RowColChangeType = RowColType;

export function rowColChangeFromKeydownEventCodeAndPiece(
  eventCode,
  piece
): RowColChangeType | undefined {
  // switch on piece
  // TODO ChessPieceEnum
  if (piece === 'knight') {
    const moveName = KNIGHT_KEYS_MAP[eventCode];
    return moveName && rowColChangeByKnightMove[moveName];
  }

  // Kings who don't even move like kings, more like a tile-based WASD game
  // TODO ChessPieceEnum
  if (piece === 'king') {
    const moveName = ROOKIFIED_KING_KEYS_MAP[eventCode];
    return moveName && rowColChangeByKingMove[moveName];
  }
}
