/**
 * After hours of disappointment and frustration,
 * I finally got some code out of ChatGPT-4 that works.
 */

import React, { useState } from 'react';

// Enum for player
enum Player {
  None = 'none',
  One = 'one',
  Two = 'two',
}

// Initialize a 3x3 board with all empty spots
const initializeBoard = (): Player[][] => {
  return Array(3)
    .fill(null)
    .map(() => Array(3).fill(Player.None));
};

const ChessGPTv1 = () => {
  const [board, setBoard] = useState(initializeBoard);
  const [currentPlayer, setCurrentPlayer] = useState(Player.One);

  const handleCellClick = (x: number, y: number) => {
    if (board[x][y] !== Player.None) return; // If the cell is not empty, ignore the click

    // Copy the current board for immutability
    const newBoard = board.map((row) => [...row]);
    // Update the clicked cell with the current player's marker
    newBoard[x][y] = currentPlayer;
    // Update state
    setBoard(newBoard);
    setCurrentPlayer(currentPlayer === Player.One ? Player.Two : Player.One);
  };

  return (
    <div className="App">
      <div>
        {board.map((row, x) => (
          <div key={x}>
            {row.map((cell, y) => (
              <button key={y} onClick={() => handleCellClick(x, y)}>
                {cell}
              </button>
            ))}
          </div>
        ))}
      </div>
      <div>Current Player: {currentPlayer}</div>
    </div>
  );
};

export default ChessGPTv1;
