import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IGame, IGameStatus, onGame } from './Lobby/api';
import CodeNames from './Lobby/Games/CodeNames';

export const variantFromGameStatus = (status: IGameStatus) => {
  if (status === 'setup') {
    return 'success'; // green
  }
  if (status === 'playing') {
    return 'warning'; // yellow
  }
  if (status === 'done') {
    return 'danger'; // red
  }
};

const Game = (props) => {
  const { id } = props.match.params;
  const [game, setGame] = useState<IGame>();

  // TODO could pass the whole game object to the CodeNames component instead of doubling this
  useEffect(() => {
    onGame(id, setGame);
  }, [id]);

  return (
    <>
      <div className="ms-3 mb-2 small">
        <Link to="/games">&laquo; games</Link>
      </div>
      {/* TODO handle unrecognized game types */}
      {game?.type === 'codenames' && <CodeNames id={id} />}
    </>
  );
};

export default Game;
