import { useCallback, useEffect } from 'react';

export default function useHandleKeyboardInput({ position, setPosition }) {
  const handleKeyDown = useCallback((event) => {
    switch (event.key) {
      case 'w':
      case 'ArrowUp':
        // handle up
        setPosition({ x: position.x, y: position.y + 1 });
        break;
      case 'a':
      case 'ArrowLeft':
        // handle left
        setPosition({ x: position.x - 1, y: position.y });
        break;
      case 's':
      case 'ArrowDown':
        // handle down
        setPosition({ x: position.x, y: position.y - 1 });
        break;
      case 'd':
      case 'ArrowRight':
        // handle right
        setPosition({ x: position.x + 1, y: position.y });
        break;
      case 'Escape':
        // handle escape
        console.log('Escape key pressed');
        break;
      case ' ':
        // handle space
        console.log('Spacebar pressed');
        break;
      case 'Tab':
        // handle tab
        console.log('Tab key pressed');
        break;
      default:
        if (!isNaN(Number(event.key))) {
          // handle number keys
          console.log(`Number key ${event.key} pressed`);
        }
        break;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup when component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
  return;
}
