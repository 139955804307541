import { useMemo } from 'react';
import { GameProvider, useGameContext } from './GameContext';
import './HappyIsland.css';

type Cell = {
  water: boolean;
};

function CellEmoji({ cell }: { cell: Cell }) {
  const [emoji, fontSize] = useMemo(() => {
    if (cell.player) {
      return ['👩‍🌾', '5vw'];
    }
    if (cell.water) {
      return ['🌊', '4vw'];
    }
    return ['🌴', '4vw'];
  }, [cell]);
  return <div style={{ fontSize }}>{emoji}</div>;
}

function BoardCell({ cell }: { cell: Cell }) {
  return (
    <div
      className="cell"
      style={{
        backgroundColor: `${cell.water ? 'cyan' : 'palegreen'}`,
        border: `${cell.player ? '2px solid rgb(255, 175, 7)' : ''}`,
      }}
    >
      <CellEmoji cell={cell} />
    </div>
  );
}

function Board() {
  const { boardPlayerUnion, position } = useGameContext();

  return (
    <div className="layout">
      <div className="title-bar">
        <h1 className="font1">happy island</h1>
      </div>
      <div className="board" style={{ display: 'flex' }}>
        {boardPlayerUnion?.map((rowCells, i) => (
          <div key={i}>
            {rowCells.map((cell: Cell, j) => (
              <BoardCell cell={cell} key={j} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default function HappyIslandAdventureGame() {
  return (
    <GameProvider>
      <Board />
    </GameProvider>
  );
}
