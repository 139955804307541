import { Button } from 'react-bootstrap';

export default function DeleteButton({ children, onClick }) {
  return (
    <Button
      variant="danger"
      size="sm"
      className="ms-2"
      style={{
        borderRadius: '2rem',
        lineHeight: '0.6rem',
      }}
      onClick={onClick}
    >
      {children || <>&times;</>}
    </Button>
  );
}
