/**
 * Dashboard
 *
 * This xBook Dashboard App is a view of your personal priorities.
 *
 * For example, you may believe highly in the Writing To Think and Diamond From Rough writing tools.
 * For example, you may believe the in-development Bad Habits tool is most important for your personal goals/values right now.
 * In the above examples combined, you would see a dashboard that has morphed to support you in those preferences.
 *
 * @todo
 * - I will first begin by building it as useful for myself.
 * - - @TODO data persistence and may as well per user
 * - I can then extend it out as I wish, for UI selection features or access to others.
 *   Ideally, I make it until it's genuinely great for me in the mvp but truly valuable,
 * - Then develop access for others, doesn't have to be massive scale yet but get users.
 * - At this point, if people are raving and/or people are spending consistent cash on it
 *   Then venture capitalists are interested for exit or you can keep playing the game;
 *   The next step in the game irl here is to finalize and ship and promote;
 *   Don't optimize to hype first since this IS my first rodeo;
 *   Make sure it's genuinely available ie on the app store or as Premium is truly available now;
 *   Btw go sign up for Premium status at xbk.io/premium yes I'm plugging here in this file comment;
 *   watch and learn.
 * - Spread it to the people who will likely genuinely benefit from it
 *   And as the interest is strong, prepare for more users, then get more users
 *   Make sure you always have enough supply (can hangle the possible influx)
 *   But don't over-optimize, just develop for yourself;
 *   In fact, me developing this on live xbk has upsides and downsides, downsides per above
 *   But upsides because if/when an idea lands as good, it's already back-boned on a live functional platform
 */

import React from 'react';
import Habits from './Widgets/Habits';
import Card from '../../../shared/Card';

const Dashboard = () => (
  <Card title="Dashboard">
    <Habits />
  </Card>
);

export default Dashboard;
