import React, { useState } from 'react';
import { Prompt } from 'react-router';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import AppContext from '../../AppContext';
import countWords from '../../shared/countWords';
import { SelectModality } from '../../shared/Modalities';
import EmotionalAwareness from '../../shared/Modalities/EmotionalAwareness';

const ToggleShow = ({ children, icon = <>&#x1f9ee;</> }) => {
  const [show, setShow] = useState(false);
  return <span onClick={() => setShow(!show)}>{show ? children : icon}</span>;
};

export default class PostForm extends React.Component {
  constructor() {
    super();
    this.state = {
      content: '',
      contentDirty: false,
      modalityDirty: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  static contextType = AppContext;
  modality = () => this.context.modality;
  user = () => this.context.user;

  // @todo known @bug: this should not trigger when navigation is result of attempting to Post the content!
  shouldBlockNavigation = () => {
    return this.state.contentDirty || this.state.modalityDirty;
  };

  updateNavigationPrompt = () => {
    // https://stackoverflow.com/a/1119324/1438029
    if (this.shouldBlockNavigation()) {
      // Enable navigation prompt
      window.onbeforeunload = () => true;
    } else {
      // Remove navigation prompt
      window.onbeforeunload = null;
    }
  };

  componentDidMount() {
    this.props.content && this.setState({ content: this.props.content });
  }

  handleChange(event) {
    const content = event.target.value;

    if (
      this.props.characterLimit &&
      content.length > this.props.characterLimit
    ) {
      return;
    }

    this.setState(
      {
        content: content,
        contentDirty: true,
      },
      this.updateNavigationPrompt
    );
  }

  render() {
    const isPostForm = this.props.multiline;
    const isTagForm = !this.props.multiline;
    return (
      <>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (this.state.content === '') {
              return;
            }
            const successCallback = () => {
              this.setState({ content: '' });
              this.props.onSuccess && this.props.onSuccess();
              this.context.setModality(null);
            };
            if (
              !this.props.onSubmit &&
              this.props.onSubmitEditHack &&
              this.props.editPostIdHack
            ) {
              this.props.onSubmitEditHack({
                id: this.props.editPostIdHack,
                content: this.state.content,
                uid: this.user().uid,
                successCallback,
              });
            } else {
              this.props.onSubmit({
                content: this.state.content,
                replyToId: this.props.replyToId || null,
                successCallback,
                uid: this.user().uid,
                room: this.props.hackRoom,
                //TODO this hack prevents non-premium user from submitting a post with modality
                // but it's here floating in the middle; should be lifted out to both ends
                modality: this.user().isPremium ? this.modality() : null,
              });
            }
            this.setState({ contentDirty: false, modalityDirty: false });
          }}
        >
          {this.user().isPremium && isPostForm && !this.props.replyToId && (
            <div className="mb-2">
              <SelectModality
                room={this.props.hackRoom}
                navigate={this.props.navigateOnModalitySelect}
                onSuccess={() => {
                  this.setState(
                    { modalityDirty: true },
                    this.updateNavigationPrompt
                  );
                }}
              />
              {/* TODO shouldn't need to do this exceptional thing here anymore */}
              {this.modality() === 'emotionalawareness' && (
                <Card className="mt-2">
                  <Card.Body>
                    <EmotionalAwareness content={this.state.content} />
                  </Card.Body>
                </Card>
              )}
            </div>
          )}
          <div className="mt-1">
            {isPostForm && (
              <Form.Control
                as="textarea"
                rows={4}
                placeholder={this.props.placeholder}
                value={this.state.content}
                onChange={this.handleChange}
                autoFocus={true}
              />
            )}
            {isTagForm && (
              <Form.Control
                type="text"
                placeholder={this.props.placeholder}
                value={this.state.content}
                onChange={this.handleChange}
                size={this.props.small ? 'sm' : null}
                autoFocus={true}
              />
            )}
          </div>
          {isPostForm && (
            // @TODO better ui/ux
            // @TODO better code
            <>
              <span className="float-end text-muted">
                <ToggleShow icon={<>&#x1f9ee;</>}>
                  {countWords(this.state.content)}
                </ToggleShow>
              </span>
              {Number.isInteger(this.props.wordCountParentContent) && (
                <span className="float-end text-muted">
                  <ToggleShow icon={<>&#x2696;</>}>
                    {Math.trunc(
                      (100 * countWords(this.state.content)) /
                        this.props.wordCountParentContent
                    ) + ' % of parent post words'}
                  </ToggleShow>
                </span>
              )}
            </>
          )}
          {!this.props.hideSubmitButton && (
            <Button
              className="mt-1"
              variant="primary"
              type="submit"
              // disabled={this.state.content === ''}
            >
              Post
            </Button>
          )}
        </Form>
        {/* https://stackoverflow.com/a/49163569/1438029 */}
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
      </>
    );
  }
}
