import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import Card from '../../shared/Card';
import GamesLobby from './Lobby';
import { Alpha, Beta } from '../../shared/icons';

const Games = () => {
  return (
    <Card title="Games">
      <Tabs defaultActiveKey="lobby" className="my-3">
        <Tab eventKey="lobby" title="Multiplayer">
          <Tabs defaultActiveKey="lobby">
            <Tab eventKey="lobby" title="Games Lobby">
              <GamesLobby />
            </Tab>
            <Tab eventKey="list" title="Single Instance Multiplayer">
              <ul>
                <li>
                  <Link to="/chess">Chess</Link>
                  <Alpha />
                </li>
                <li>
                  {/* TODO make more prominent */}
                  <Link to="/cultivation">Cultivation</Link>
                  <Beta />
                </li>
                <li>
                  <Link to="/tictactoe">Tic-Tac-Toe</Link>
                  <Alpha />
                </li>
              </ul>
            </Tab>
          </Tabs>
        </Tab>

        <Tab eventKey="solo" title="Single Player">
          <ul>
            <li>
              <Link to="/chiv">Chiv Chess</Link>
              <Beta />
            </li>
            <li>
              <Link to="/fight-sim">Fight Sim</Link>
              <Alpha />
            </li>
            <li>
              <Link to="/incremental-clicker-game">Incremental Clicker</Link>
              <Beta />
            </li>
            <li>
              <Link to="/simcity">Sim City</Link>
              <Beta />
            </li>
            <li>
              <Link to="/time-balls">Time Balls</Link>
              <Alpha />
            </li>
          </ul>
        </Tab>
      </Tabs>
    </Card>
  );
};
export default Games;
