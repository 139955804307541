import { db } from '../components/firebase.js';

export const getRooms = (callback) => {
  const rooms = {};
  db.collection('rooms')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        rooms[doc.id] = doc.data();
        rooms[doc.id].id = doc.id;
      });
      callback(rooms);
    });
};
